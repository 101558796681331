import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { EssayHelper } from "../../helpers/essay.helper";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from '../common/app-top-bar';
import AppLoading from '../common/app-loading';
import '../../css/property-list.css';
import {
  Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../../contexts/context-property';
import { AppHelper } from '../../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ToolbarPlugin from '../plugin/editor-toolbar';

export default function PopQuestion({
  onClose, open, onSubmit
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const oUser = AppHelper.userCacheGet();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });
  const [questionData, setQuestionData] = useState({
    text: '',
    files: [],
    question: '',
  });

  const [questionList, setQuestionList] = useState([]);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [authOK, setAuthOK] = React.useState(false);
  const questionTypes = [
    { code: '-', name: '-- Select --' },
    { code: 'BUG', name: 'BUG REPORT' },
    { code: 'FEATURE', name: 'FEATURE REQUEST' },
    { code: 'GENERAL', name: 'GENERAL QUESTION' },
  ];
  const [question, setQuestion] = useState('');



  const fetchQuestions = async () => {
    var _res = await AppHelper.apiPost('api/question/list', { token: oUser.token });
    console.log('_res', _res);
    if (!_res || !_res.status) {
      alert("Fail to get question");
      return;
    }

    setQuestionList(_res.data);
  };

  const questionSelect = async (arg) => {
    setQuestion(arg.target.value);
  };

  useEffect(() => {
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);

    if (_user) {

      fetchQuestions();
    }
  }, []);

  const handleSubmit = async () => {
    await handleQuestionSubmit();
  };

  const handleQuestionSubmit = async () => {
    if (question == '') {
      alert("Select a question");
      return;
    }
    const _q = questionList.find(r=>r.questionId == question);
    if(!_q){
      alert('Invalid Question');
      return;
    }
    if(onSubmit){
      onSubmit(_q);
    }
    onClose(question);
  };

  const handleClose = () => { onClose() };

  //const editor = useLexicalEditor(editorConfig);
  const [textContent, setTextContent] = useState('');

  //const editorInstance = useLexicalEditor(editorConfig);
  // const updateTextContent = () => {
  //   // Get the editor state and extract the text content
  //   editorRef.current.getEditorState().read(() => {
  //       const root = editorRef.current.getEditorState().read();
  //       const text = root.getTextContent();
  //       setTextContent(text);
  //     });
  // };
  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth>
        <DialogTitle>{"SELECT QUESTION"}</DialogTitle>
        <Box pl={2} pr={2} pb={2} alignItems="flex-start">

          <Grid>
            <Grid item xs={12} sm={6}>
              <Box m={.5} >

                <Grid item>
                  {questionList && questionList.length > 0 &&
                    <Select labelId='lblQuestion' label="Question"
                      value={question}
                      onChange={questionSelect}
                      inputProps={{
                        //disableUnderline: true
                      }}
                      sx={{ "& fieldset": {} }}
                    >
                      <MenuItem value="" disabled>
                        Please select
                      </MenuItem>
                      {questionList.map((item) => (
                        <MenuItem key={item.questionId} value={item.questionId}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                </Grid>



              </Box>
            </Grid>
            <Grid container pt={.5}>
              <Grid item xs={6}>

              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  {/* <Button onClick={onAddSubmit} color="info" variant="outlined">NEXT</Button> */}
                  <LoadingButton
                    onClick={handleSubmit}
                    // endIcon={<SendIcon />}
                    loading={isSubmitting}
                    //loadingPosition="start"
                    variant="contained"
                  >
                    <span>DONE</span>
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </Dialog>
    </>
  );
}
