import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../AppHelper';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SaveIcon from '@mui/icons-material/Save';
import { pink, green, blue } from '@mui/material/colors';
import {
  Button, Box, ButtonGroup, Chip, Dialog, DialogTitle, Grid, IconButton,
  Paper, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
const configJson = require('../config.json?v=20230925');
dayjs.extend(relativeTime);

export default function PopAccessRights ({
  onClose, open, profile, tuiton
}){
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  const [email1, setEmail1] = useState(oUser? (oUser.email1 || ''):'');
  const [email1Sent, setEmail1Sent] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  
  useEffect(()=>{
    console.log('--', profile);
    if(!open || !profile || !profile.access) return;
    setEmail1Sent(false);
    setEmail1("");
    // setGradeList([
    //   {v:'P1', a:0}, {v:'P2', a:0}, {v:'P3', a:0},
    //   {v:'P4', a:0}, {v:'P5', a:0}, {v:'P6', a:0},
    // ]);
    console.log(profile);
    setGradeList([...profile.access]);
  }, [open, profile]);
  const handleClose = () =>{ onClose() };
  const clickAccess = async(argGrade, argValue)=>{
    console.log(profile, argGrade, argValue);
    const oUser = AppHelper.userCacheGet();
    var _res = await AppHelper.apiPost("api/access/update", {
      token: oUser.token,
      profileId: profile.profileId,
      grade:argGrade.v,
      access:argValue
    });
    console.log(_res);
    if (!_res || !_res.status) {
      //return;
    }
    var _grades = [...gradeList];
    var _grade = _grades.find(r=>r.v==argGrade.v);
    _grade.a = argValue;
    setGradeList(_grades);
  };
  const sendEmail = async()=>{
    var recipient = [];
    var _emailCode = 'INVITE';
   
    var _user = AppHelper.userCacheGet();
    
    var _payload = {
      token: _user.token, 
      email:email1, 
	  ccEmail:[], 
      code:_emailCode, 
      param:{
		"tuiton":tuiton,
        "link":'https://essayace.ai',
		"acceptLink":configJson.siteUrl,
        "siteUrl":'https://essayace.ai'//configJson.siteUrl
      }
    };
    setIsLoading(true);
    var _res = await AppHelper.apiPost('api/tuiton/invite', _payload);
    setIsLoading(false);
    if (!_res || !_res.status) {
      alert('ERROR: Failed to send email!');
      return;
    }
    //alert('Email sent successfully!');
    setEmail1Sent(true);
  }
  return(
    <>
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>ACCESS RIGHTS (QUESTION BANK)</DialogTitle>
      <Box m={1}>
        <Grid container m={1}>
          {gradeList.map((gg, iGG) => (
            <React.Fragment key={'popAccessGrade_'+iGG}>
            <Grid item xs={4} style={{textAlign:'center'}}>
              {gg.v}
            </Grid>
            <Grid item xs={8}>
              <ButtonGroup aria-label="Basic button group" style={{marginTop:'2px'}}>
                <Button variant={gg.a==0?'contained':'outlined'} onClick={()=>clickAccess(gg, 0)}>NA</Button>
                <Button variant={gg.a==1?'contained':'outlined'} onClick={()=>clickAccess(gg, 1)}>VIEW</Button>
                <Button variant={gg.a==2?'contained':'outlined'} onClick={()=>clickAccess(gg, 2)}>MANAGE</Button>
              </ButtonGroup>
            </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
    </>
  );
}