//import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
//import { db } from "./firebase";
//import { auth } from './firebase';
import { Container, Snackbar } from '@mui/material';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { GoogleLogin } from '@react-oauth/google';

import AppTopBar from './components/common/app-top-bar';
import AppDataPolicy from './components/common/app-data-policy';

import Fab from '@mui/material/Fab';
import { AppHelper } from './AppHelper';
import SpaCheck from './components/common/spa-check';
import { useUserContext } from './contexts/context-user';

const configJson = require('./config.json');
// const pageCss ={ fab: { width: "880px", height: "880px" } };

export default function App() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [userAgent, setUserAgent] = useState('-');
  const [googleLoginTryCount, setGoogleLoginTryCount] = React.useState(0);
  const [isGoogleLoginInitOK, setIsGoogleLoginInitOK] = useState(true);
  const { getUser } = useUserContext();
  const { inviteId } = useParams();

  useEffect(() => {
    //AppHelper.cacheInviteIdSet();
    //setUserAgent(navigator.userAgent);
    detectBrowser();
    setIsLoading(true);
    const urlSearchParams = new URLSearchParams(window.location.search);
    setSearchParams(urlSearchParams);
    var _authCode = searchParams.get("code");
    var _authScope = searchParams.get("scope");
    //console.log(_authCode, _authScope);
    if(!_authCode || !_authScope){
      setIsLoading(false);
      if(!AppHelper.isEmptyString(inviteId)){
        AppHelper.cacheInviteIdSet(inviteId);
      }
      else{
        AppHelper.cacheInviteIdSet();
      }
      //console.log('configJson.oauthClientId', configJson.oauthClientId);
      googleLoginButtonInit();
      return;
    }
    AppHelper.apiFunction('app/user', {
      code: _authCode,
      isDev: configJson.isDev == 1
    }).then(_resAuth=>{
      //console.log('_resAuth',_resAuth);
      if(_resAuth && _resAuth.status!=0){
        siteLogin(_resAuth.data.email, _resAuth.data.name, _authCode);
      }
      else{
        console.log(_resAuth);
        authFailed();
      }
    });
  }, []);
  const authFailed = ()=>{
    alert('Auth failed!');
    navigate('/login');
    window.location.reload();
  }
  const siteLogin = async(_email, _name, _authCode)=>{
    //console.log('calling login api', {email: _email, name: _name});
    const _resUser = await AppHelper.apiPost('api/profile/login/', {email: _email, name: _name} 
    //{authCode: _authCode}
    );
    
    setIsLoading(false);
    //console.log(_resUser);
    if(!_resUser.status){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to login.'});
      return;
    }

    const _resProfile = await AppHelper.apiPost('api/profile/get/', {token: _resUser.token})
    if(!_resUser.status){
      setMsgBox({...msgBox, open: true, msg: 'ERROR: Failed to load profile.'});
      return;
    }
    //console.log('cache set', {token: _resUser.token, email: _email, name: _name, grade: _resUser.grade}, _resUser);
    AppHelper.userCacheSet({token: _resUser.token, email: _email, name: _resProfile.data.name, grade: _resProfile.data.grade, picture: _resProfile.photo, profileId:_resProfile.data.profileId, accountType: _resProfile.accountType, ownerId:_resProfile.ownerId});
    AppHelper.userCodeCacheSet(_authCode);
    getUser();
    
    await tutorInviteAccept(_resProfile.data.profileId, _resUser.token, _email);

    if(_resUser.firstTime == 1)
      navigate('/home');
    else{
      navigate('/profile');
    }
  };
  const tutorInviteAccept = async(_profileId, argToken, argEmail)=>{
    var _inviteId = AppHelper.cacheInviteIdGet();
    console.log('_inviteId',_inviteId);
    if(AppHelper.isEmptyString(_inviteId))
      return;
    
    const _resInvite = await AppHelper.apiPost('api/tuiton/invite/get', {profileId:_profileId, inviteId:_inviteId, token:argToken });
    if(!_resInvite.status || !_resInvite.data){
      alert('Failed to accept the Invite. Failed to read the invite info!');
      return;
    }
    if(!_resInvite.data.email || _resInvite.data.email.toLowerCase() != argEmail.toLowerCase()){
      alert('Failed to accept the Invite. Failed to verify your email!');
      return;
    }
    // if(_resInvite.data.status != 1){
    //   alert('Invite is expired or already accepted!');
    //   return;
    // }
    console.log('_resInvite',_resInvite);
    const _resCenterUpdate = await AppHelper.apiPost('api/profile/center/update', {profileId:_profileId, inviteId:_inviteId });
    const _resAccept = await AppHelper.apiPost('api/tuiton/accept', {profileId:_profileId, inviteId:_inviteId });
    const _resAccType = await AppHelper.apiPost('api/account/updateAccountType', {token:argToken, accountType:'TEA' });
    var _userCache = AppHelper.userCacheGet();
    _userCache.accountType = 'TEA';
    const _arrAccess = _resInvite.data.accessRight.split(';')
      .filter(item => item) // Filter out empty strings
      .map(item => {
        const [key, value] = item.split(':'); // Split each part by colon
        return { key, value: Number(value) };  // Create object and convert value to number
      });
    for(var i=0;i<_arrAccess.length;i++){
      var _resAccess = await AppHelper.apiPost("api/access/update", {
        token: argToken,
        profileId: _profileId,
        grade: _arrAccess[i].key,
        access: _arrAccess[i].value
      });
    }
    AppHelper.userCacheSet(_userCache);
  }
  const detectBrowser = ()=>{
    var sBrowser, sUsrAg = navigator.userAgent;
    // The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = "unknown";
    }
    setUserAgent(sBrowser);
  };

  useEffect(()=>{
    const fetchPing = async() =>{
      var _res = await AppHelper.apiAiPost('ping', {});
      console.log(_res);
    }
    const oUser = AppHelper.userCacheGet();
    if(oUser != null){
      loginUserGoHome(oUser);
    }
    else{
      //fetchPing();
    }
  }, []);

  const loginUserGoHome = async (argUser)=>{
    console.log('user no null - going home', argUser);
    await tutorInviteAccept(argUser.profileId, argUser.token, argUser.email);
    navigate('/home');
  }

  const googleLoginButtonInit = ()=>{
    //console.log('google check', window.google, googleLoginTryCount+1);
    setGoogleLoginTryCount(googleLoginTryCount+1);
    if(window.google && window.google.accounts){
      setGoogleLoginTryCount(333);
      try{
        window.google.accounts.id.initialize({
          //client_id: '114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com',
          //client_id: '40915665496-7s0fmud2k2jqdh9l4te4imnfov0h3cjl.apps.googleusercontent.com',
          client_id: configJson.oauthClientId
          //callback: handleCredentialResponse
        });
        //console.log('client_id >>>>>>>', configJson.oauthClientId);
        window.google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { 
            backgroundColor: '#638889',
            theme: "filled_blue", 
            size: "large", type:'standard', shape:'pill', 
            login_uri: configJson.loginUrl,
            ux_mode: 'redirect',
            //redirect_uri: 'https://dev.dingdong.asia',//'http://localhost:3000',//configJson.loginUrl,
            scope: 'email%20profile&access_type=offline'
          }  // customization attributes
        );
        setGoogleLoginTryCount(777);
      }
      catch(err){
        setGoogleLoginTryCount(99999);
      }
    }
    else{
      setGoogleLoginTryCount(googleLoginTryCount+5);
      //navigate('/login');
      setTimeout(() => {
        googleLoginButtonInit();
      }, 500);
      if(googleLoginTryCount>5){
        setIsGoogleLoginInitOK(false);
      }
    }
  };
  const onLoginGoogleBtn = async(event)=>{
    // console.log(event);
    // console.log(configJson.loginUrl);
    //event.preventDefault();
    setIsLoading(true);
    window.location.href = configJson.loginUrl;
    event.stopPropagation();
    //event.stopImmediatePropagation();
  }
  const onLogin = async (event) => {
    console.log(event);
    //event.preventDefault();
    setIsLoading(true);
    // const provider = new auth.GoogleAuthProvider();
    // try {
    //   var _res = await auth().signInWithPopup(provider);
    //   const _uid = _res.user.uid;
    //   var _resUser = await AppHelper.api('user/byUuid', 'POST', {uid: _uid});
    //   if(_resUser.status != 1){
    //     alert('ERROR: Failed to get the user detail!');
    //     return;
    //   }
    //   AppHelper.userCacheSet(_resUser.data);
    //   //var _res = await auth().signInWithRedirect(provider);
    //   //console.log('Auth DONE <<', _res);
    //   // var oUser = auth().currentUser;
    //   navigate('/home');
    // } catch (e) {
    //   console.log(e);
    // }
  };
  const browseSite = ()=>{
    window.open('https://edutechessay.web.app', '_blank');
  };

  return (
    <>
      <AppTopBar />
      <SpaCheck></SpaCheck>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        {isLoading?(
          <Box display='flex' alignItems='center' justifyContent='center' minHeight='100vh'>
            <CircularProgress />
          </Box>
        ):(
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="90vh"
            flexDirection='column'
          >
            {/* <a href="https://accounts.google.com/o/oauth2/v2/auth?client_id=223546144272-3mp6i7ojg4nr78mucq0612gqdcbesta6.apps.googleusercontent.com&redirect_uri=http://localhost:5000/auth/callback&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/calendar&access_type=offline"
              class="btn btn-primary">Login with Google
            </a> */}
            <div>
              <img src='/logo-login.png?v=20231101' style={{maxWidth:'180px', marginTop:'-7rem', marginBottom:'1rem'}} />
            </div>
            {/* <div id="g_id_onload"
                data-client_id="114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com"
                data-login_uri={configJson.loginUrl}
                data-auto_prompt="false">
            </div> */}
            {/* <a onClick={onLogin} href={configJson.loginUrl} style={{textDecoration:'none'}}>
              
            </a> */}
            <Box textAlign={'justify'} sx={{textAlignLast:'center'}} color={'#1b5e20'}>
              Welcome to Essay Ace, your journey to becoming a writing wizard starts here! Tailored for primary school students, our platform makes writing fun and learning easy. Through personalized feedback and creative suggestions, we aim to boost confidence and spark a love of writing. Dive into the adventure of words and watch your skills soar. Ready for the magic? Log in and let’s create wonders together!
            </Box>
            <div onClick={onLoginGoogleBtn} style={{position:'relative', marginTop:'2rem'}}>
              <div id="buttonDiv" className="g_id_signin" style={{transform: 'scale(1,1)'}}>
              </div>
              <div onClick={onLoginGoogleBtn} style={{position:'absolute', top:0, left:0, width:'100%', backgroundColor:'rgba(0,0,0,0)', height:'100%', cursor:'pointer' }}></div>
            </div>
            { !isGoogleLoginInitOK &&
              <div>
                Unsupported view. Click <Button onClick={browseSite}>here</Button> to browse on default browser.
              </div>
            }
            {/* { isGoogleLoginInitOK &&
              <div style={{fontSize:'0.6rem'}}>({googleLoginTryCount})</div>
            } */}
            <Box mt={'1rem'} color={'#1b5e20'} textAlign={'justify'} sx={{textAlignLast:'center', fontWeight:'normal'}} >
              <div style={{fontWeight:'bold'}}>
              By invite only. Please do not share the link.
              </div>
              <div>
                
              </div>
            </Box>
            {/* <GoogleLogin
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
              ux_mode='redirect'
              redirect_uri={configJson.loginUrl}
            /> */}
            {/* <div>
              <a onClick={onLogin} href={configJson.loginUrl} className="btn btn-primary glitch">
                <Fab sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
                  <div>
                    <div>
                      <LockOpenIcon />
                    </div>
                    <div>
                      Login
                    </div>
                  </div>
                </Fab>
              </a>
            </div> */}
            {/* <a href="https://accounts.google.com/o/oauth2/v2/auth?client_id=223546144272-3mp6i7ojg4nr78mucq0612gqdcbesta6.apps.googleusercontent.com&redirect_uri=https://mindful-origin-398206.web.app&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/calendar&access_type=offline"
              className="btn btn-primary">
              <Fab sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
                Login
              </Fab>
            </a> */}
            {/* <Fab onClick={onLogin} sx={{width:'12rem', height:'12rem'}} color="primary" aria-label="Login">
              Login
            </Fab> */}
            {/* backgroundColor:'#f9efdb' */}
            <AppBar position="fixed" style={{ top: 'auto', bottom: 0, backgroundColor:'#fff', color:'#666', boxShadow:'none' }}>
              <Toolbar style={{textAlign:'center'}}>
                <div variant="body2" color="inherit" style={{width:'100%', fontSize:'0.9rem'}}>
                  {/* &copy; 2024 Your Company Name. All rights reserved. */}
                  {/* Version: Pre-alpha testing - 20240528*/}
                  {/* <div style={{fontSize:'0.5rem'}}>{userAgent}</div> */}
                </div>
              </Toolbar>
            </AppBar>
          </Box>
        )}
        {/* <Box component="footer"
          sx={{ py: 1, px: 2, mt: 'auto' }}
        >
          <Container maxWidth="sm">
            <Box display='flex' justifyContent='center'>
              <a href='mailto:contactus@abcd.com' style={{textDecoration:'none'}}>contactus@abcd.com</a>
              Version: Pre-alpha testing
            </Box>
            <AppDataPolicy />
          </Container>
        </Box> */}
      </Container>
      <Snackbar
        open={msgBox.open}
        autoHideDuration={6000}
        message={msgBox.msg}
        anchorOrigin={{ vertical:msgBox.vertical, horizontal:msgBox.horizontal }}
        onClose={() => setMsgBox({...msgBox, open: false})}
      />
    </>
  );
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}
