import { createContext, useContext, useEffect, useState } from 'react';
import { AppHelper } from '../AppHelper';
import { EssayHelper } from '../helpers/essay.helper';
import { UserContext } from './context-user';

const dayjs = require('dayjs');
const EssayContext = createContext();

export const useEssayContext = () =>{
  return useContext(EssayContext);
};

export const EssayProvider = ({ children })=>{
  const { oUser } = useContext(UserContext);
  const [essayList, setEssayList] = useState([]);
  const [essayIdSelected, setEssayIdSelected] = useState('');
  const [isEssayListLoaded, setIsEssayListLoaded] = useState(false);
  const [isEssayListLoading, setIsEssayListLoading] = useState(true);
  const _scoreCodesV2 = [
    { c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'ST', c2: 'structure', n:'Structure', v:0, p:0, eg: [], sub: [], expand: false },
    //{ c:'FL', c2: 'flow', n:'Flow', v:0, p:0 },
  ];
  useEffect(() => {
    if(!oUser) return;
    //console.log('^^^^^ Eassy Context OUser', oUser);
    loadEssayList(oUser);
  }, [oUser]);

  const loadEssayList = async (argUser, argEssayId=null) => {
    //console.log('--- context :: loadEssayList ---', argUser);
    if(!argUser) return;
    setIsEssayListLoading(true);
    // var _rulesJsonP1 = await EssayHelper.rulesJsonByProfile(argUser.token, 'P1');
    // var _rulesJsonP2 = await EssayHelper.rulesJsonByProfile(argUser.token, 'P2');
    // var _rulesJsonP3 = await EssayHelper.rulesJsonByProfile(argUser.token, 'P3');
    // var _rulesJsonP4 = await EssayHelper.rulesJsonByProfile(argUser.token, 'P4');
    // var _rulesJsonP5 = await EssayHelper.rulesJsonByProfile(argUser.token, 'P5');
    // var _rulesJsonP6 = await EssayHelper.rulesJsonByProfile(argUser.token, 'P6');
    const [_rulesJsonP1, _rulesJsonP2, _rulesJsonP3, _rulesJsonP4, _rulesJsonP5, _rulesJsonP6] = await Promise.all([
      EssayHelper.rulesJsonByProfile(argUser.token, 'P1'),
      EssayHelper.rulesJsonByProfile(argUser.token, 'P2'),
      EssayHelper.rulesJsonByProfile(argUser.token, 'P3'),
      EssayHelper.rulesJsonByProfile(argUser.token, 'P4'),
      EssayHelper.rulesJsonByProfile(argUser.token, 'P5'),
      EssayHelper.rulesJsonByProfile(argUser.token, 'P6'),
    ]);
    var _rulesJsonList = [
      { code:'P1', data: _rulesJsonP1},
      { code:'P2', data: _rulesJsonP2},
      { code:'P3', data: _rulesJsonP3},
      { code:'P4', data: _rulesJsonP4},
      { code:'P5', data: _rulesJsonP5},
      { code:'P6', data: _rulesJsonP6},
    ];
    var _res = await AppHelper.apiPost('api/essay/list/v3', {token: argUser.token, profileId: argUser.profileId});
    setIsEssayListLoaded(true);
    setIsEssayListLoading(false);
    if(!_res || !_res.status){
      setEssayList([]);
      console.log('set empty []', _res);
      return;
    }
    var _isAnyInProgress = false;
    //console.log('loadEssayList', _res.data);
    //if(1==1) return;
    _res.data.forEach(r=>{
      var _ts = dayjs(r.timestampUtc);
      r.tCode = _ts.format('YYYYMMDDHHmmss');
      //r.tTitle = `Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');
      r.tTitle = _ts.format("DD MMM YY' hh:mm A");
      r.viewTitle = `${r.topic}`;
      r.version = r.avgScore != null ? 1 : 2;
      var _score = r.version == 1 ? r.avgScore : r.avgScore2;
      //console.log('r.version',r.version);
      if(r.version == 1){
        r.avgScore = parseFloat(_score);
        r.avgScoreP = (r.avgScore / 5)*100;
      }
      else if(r.version == 2){
        var _scores = [..._scoreCodesV2];
        r.version = EssayHelper.scoreVersionNumFromScore(r);

        var _v2TotalScore = 0;
        if(r.version >= 3.0){
          var _rule = _rulesJsonList.find(x=>x.code == r.grade);
          if(_rule){
            var _recRule = EssayHelper.essayInfoGet({data:{grade:r.grade}, score:r.scores}, _rule.data);
            r.avgScoreP = _recRule.avgScoreP_r;
          }
          else{
            //var _recByRule = EssayHelper.essayInfoGet()
            _scores.forEach(_rScore=>{
              var _arrScRec = r.scores.filter(_x=>_x.scoreCode == _rScore.c);
              const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
              _rScore.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
              _rScore.v = parseFloat(_rScore.v.toFixed(2));
              if(_rScore.c == 'ST'){
                _rScore.v = parseFloat((_rScore.v * 2.5).toFixed(2));
              }
              else if(_rScore.c == 'VC' && _arrScRec.length<2){
                _rScore.v = parseFloat((_rScore.v / 2).toFixed(2));
              }
              _v2TotalScore += _rScore.v;
              // console.log('_scSum',_scSum, r);
              // if((r.version == 3 || r.version == 3.1) && (_rScore.c == 'SP' || _rScore.c == 'GM' || _rScore.c == 'VC')){
              //   _scSum = _scSum / 2.5;
              // }
              // _v2TotalScore += _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
            });
            r.avgScoreP = (_v2TotalScore / (10*4))*100;
          }
        }
        else{
          _scores.forEach(_rScore=>{
            var _arrScRec = r.scores.filter(_x=>_x.scoreCode == _rScore.c);
            var _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
            //console.log('_scSum',_scSum, r);
            if((r.version == 3 || r.version == 3.1) && (_rScore.c == 'SP' || _rScore.c == 'GM' || _rScore.c == 'VC')){
              _scSum = _scSum / 2.5;
            }
            _v2TotalScore += _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
          });
          r.avgScore = parseFloat(_v2TotalScore.toFixed(2));
          r.avgScoreP = (r.avgScore / 16)*100;
        }
      }
      r.versionTitle = r.version == 4.0 ? 'v4.0' 
                        : r.version == 3.1 ? 'v3.1' :r.version == 3 ? 'v3.0' 
                        : r.version == 2.2 ? 'v2.2' : r.version == 2.1 ? 'v2.1' : r.version == 2 ? 'v2.0' : 'v.1.0';

      if(r.status>0 && r.status<5){
        var _diffMinutes = dayjs.utc().diff(dayjs(r.timestampUtc), 'minutes');
        if(_diffMinutes<15){
          r.isInProgress = true;
          _isAnyInProgress = true;
        }
      }
    });
    //if(1==1) return;
    var _list = [...AppHelper.sortList(_res.data, 'tCode', 2)];
    if(AppHelper.sampleEssay()){
      _list = _list.filter(r=>r.essayId != AppHelper.sampleEssay().essayId);
      _list.push(AppHelper.sampleEssay())
    }
    setEssayList(_list);
    //console.log('setEssayList', _list);
    //console.log('setEssayList-selectEssay', argEssayId);
    if(argEssayId){
      // setTimeout(() => {
      //   essaySelect(argEssayId);
      // }, 1000*1);
      essaySelectByList(argEssayId, _list);
    }
  };
  const essaySelect = (argEssayId)=>{
    var _list = [...essayList];
    _list.forEach(x=>{
      x.selected = x.essayId == argEssayId ? '1' : '0';
    });
    setEssayList(_list);
  }
  const essaySelectByList = (argEssayId, _list)=>{
    _list.forEach(x=>{
      x.selected = x.essayId == argEssayId ? '1' : '0';
    });
    setEssayList(_list);
  }
  const essayRemove = (argEssayId)=>{
    var _list = [...essayList];
    _list = _list.filter(x=>x.essayId != argEssayId);
    _list.map(x=>x.selected = 0);
    setEssayList(_list);
  }

  return (
    <EssayContext.Provider value={{essayList, setEssayList, loadEssayList, isEssayListLoaded, setIsEssayListLoaded, essaySelect, isEssayListLoading, essayRemove}}>
      { children }
    </EssayContext.Provider>
  )
}
