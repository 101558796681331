import logo from '../../';
import './essay-view.css';
import '../../App.css';
import React, { useEffect, useState, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import {
  Avatar, Button, Box, Card, Chip, chipClasses, CircularProgress, Dialog, DialogTitle, Fab, FormControl, Grid, IconButton, InputLabel,
  ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, DialogContent,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Tooltip, Typography
} from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { diffChars, diffWords, diffWordsWithSpace } from 'diff';
import { AppHelper } from '../../AppHelper';
import ScanView from './scan-view';
import EssayStatus from './essay-status';
import PopErrorAdd from './pop-error-add';
import PopGMErrors from './pop-GM-errors';
import PopSPErrors from './pop-SP-errors';
import PopVCAErrors from './pop-VCA-errors';
import PopVCXErrors from './pop-VCX-errors';
import PopScore from './pop-score';
import PopComment from "../common/pop-comment";
import PopInProgress from '../common/pop-inprogress';
import PopResubmit from '../common/pop-resubmit';
import ScoreSubExpand from './score-sub-expand';
import dayjs from 'dayjs';
import {QRCodeSVG} from 'qrcode.react';
import { useEssayContext } from "../../contexts/context-essay";
import { EssayHelper } from '../../helpers/essay.helper';
import PopShare from './pop-share';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const configJson = require('../../config.json');

function SuggestionPopup(props){
  const { onClose, open, suggestPopItems } = props;
  const handleClose = () =>{ onClose() };
  //const handleListItemClick = (value) =>{ onClose(value) };
  
  return(
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>EXAMPLE IMPROVEMENTS</DialogTitle>
      <Grid container>
        <Grid item xs={12}>
          { suggestPopItems && suggestPopItems.map((sg,iSg)=>(
            <ListItem key={'sug_i_' + iSg}>
              {/* <ListItemAvatar>
                <Avatar><TipsAndUpdatesIcon /></Avatar>
              </ListItemAvatar> */}
              <ListItemIcon sx={{minWidth:'40px'}}>
              <TipsAndUpdatesIcon sx={{color:'rgb(157, 188, 152)'}} />
              </ListItemIcon>
              <ListItemText
                primary={sg}
                // secondary={secondary ? 'Secondary text' : null}
              />
            </ListItem>
          ))
          }
        </Grid>
        
        <Grid item xs={12} p={2} pt={0} pb={0.5}>
          <Button onClick={handleClose} sx={{width:'100%'}}>CLOSE</Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
function PopShowLog(props) {
  const { onClose, open, log } = props;
  const handleClose = () => { onClose() };

	 const parseLog = (logString) => {
	  const logEntries = logString.split('\n');
	  return logEntries.map((entry) => {
		const [time, info, ...statusAndRemark] = entry.split(' ');
		const remark = statusAndRemark.join(' ');
		const status = remark.split(',')[1];
		 return { time, remark:remark.split(',')[0], status};
	  }).filter(entry => entry !== null);
	};
  return (
    <Dialog onClose={handleClose} fullWidth maxWidth="xs" open={open}>
      <DialogTitle>LOG</DialogTitle>
      <Box padding={'0.5rem'}>
        {log && log.length > 0 &&
         <TableContainer component={Paper}>
              <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Remark</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parseLog(log).map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>{entry.time}</TableCell>
                    <TableCell>{entry.status}</TableCell>
                    <TableCell>{entry.remark}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Box>
    </Dialog>
  );
}
function PopShowFeedback(props){
   const { onClose, open, onSubmit, feedbackData, setFeedbackData } = props;
   const [loading, setLoading] = useState(false);

  const handleClose = () => { onClose() };
  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };

  
  return(
      <Dialog onClose={handleClose} fullWidth maxWidth="xs" open={open}>
		<DialogTitle>Feedback</DialogTitle>
		 <DialogContent>
			<Grid container direction="column" spacing={3}>
			  <Grid item>
			  <FormControl fullWidth variant="outlined" >
				<InputLabel id="feedback-type-label">Feedback Type</InputLabel>
				<Select
				  labelId="feedback-type-label"
				  value={feedbackData.type}
				  onChange={(e) => setFeedbackData({ ...feedbackData, type: e.target.value })}
				  label="Feedback Type"
				>
				  <MenuItem value={1}>Feedback about this essay evaluation</MenuItem>
				  <MenuItem value={2}>New features I would like</MenuItem>
				  <MenuItem value={3}>Love this product and would like to chat</MenuItem>
				</Select>
			  </FormControl>
			</Grid>
			 <Grid item>
			  <TextField
				label="Feedback Text"
				multiline
				rows={4}
				variant="outlined"
				fullWidth
				value={feedbackData.text}
				onChange={(e) => setFeedbackData({ ...feedbackData, text: e.target.value })}
			  />
			 </Grid>
			  <Grid item>
			  <input
				type="file"
				accept="image/*"
				multiple
				onChange={(e) => setFeedbackData({ ...feedbackData, files: e.target.files })}
			  />
			  </Grid>
			 
			 <Grid item>
            <Grid container justifyContent="center">
			 
			 {loading ? (
  <CircularProgress />
) : (
  <Button
    sx={{ backgroundColor: 'rgb(99, 136, 137)', ":hover": { backgroundColor: 'rgb(157, 188, 152)' } }}
    onClick={handleSubmit}
    color="info"
    variant="contained"
  >
    Submit
  </Button>
)}
			 </Grid></Grid>
		</Grid>
		 </DialogContent>
		 <Button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
	  </Dialog>
	  
  );
}

export default function EssayView(props) {
  const { drawerWidth, isClosing, viewMode, essayFormDone, profileId, isSa, isSample, isShared, printMode, scroll2End, qrValue } = props;
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [authOK, setAuthOK] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ oUser, setOUser] = useState(null);
  const [msgText, setMsgText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [wordsCount, setWordsCount] = useState(0);
  const [wordsCount2, setWordsCount2] = useState(0);
  const [grade, setGrade] = useState('P5');
  const [recEssay, setRecEssay] = useState({essayId:'', imgs:[], profileId: '', topic:'', grade:'', text: '', email:'', student:''});
  const [isMyEssay, setIsMyEssay] = useState(false);
  const boxOverall = useRef(null);

  useEffect(() => {
    AppHelper.apiAuditPost("P-ES", props.essayId || "");
  }, []);
  useEffect(()=>{
    setIsMyEssay(oUser && oUser.profileId && recEssay && oUser.profileId == recEssay.profileId);
    setTimeout(() => {
      if(scroll2End){
        //window.scrollTo(0, document.body.scrollHeight);
        const element = document.getElementById('boxWordCount');
        if (element) {
          //element.scrollIntoView({ behavior: 'smooth' });
          
          const rect = element.getBoundingClientRect();
          const y = rect.top + window.scrollY;
          window.scrollTo({ top: y-45, behavior: 'smooth' });
        }
      }
      // if (boxOverall.current) {
      //   boxOverall.current.scrollIntoView({ behavior: 'smooth' });
      // }
      // else console.log('no boxOverall');
    }, 1000*1);
  }, [oUser, recEssay]);

  //const [recEssayC, setRecEssayC] = useState(`"I'm going to get a puzzle for Mary!\" I squealed excitedly. First, I bought a puzzle from the nearby shopping mall. Then, I wrapped it up in some wrapping paper. After that, I put a pink bow on top. I also made a handmade pop-up birthday card!\n\nAs I was too careless and excited, I forgot the birthday gift and the card. On the way walking there, I saw trees, flowers, and a cloud shaped like a hat. It was a long way. Finally, I reached Mary's house. It was a big mansion. Ding dong went the bell as I pressed it. I was surprised to see a servant dressed in a white robe! But I was even more surprised when I went in. In the mansion, there were a lot of kids and adults, so I had to squeeze through the crowd to find Mary. The ceiling had a chandelier too.\n\nMary was dressed in a pink and yellow dress. She also had a crown on her. Looking at me, I knew she was looking for a birthday gift, so I scrambled in my bag to find the gift, but to my dismay, the gift wasn't in my bag! Cold sweat dripped down my face and my heart palpitated furiously. I felt embarrassed, so I apologized profusely. When the kids saw this, they burst out laughing, saying \"Ha, Ha, Ha, you didn't bring the gift.\" Fingers pointed towards me like missiles pointing at me. I felt like digging a hole in the ground and hiding in it. Mary giggled while she forgave me.\n\nAs the saying goes, 'Once bitten, twice shy', I vowed to be more careful in the future and learned to keep things in a bag before going out with them. We must also check before we leave to not miss out on anything.`);
  const [recEssayC, setRecEssayC] = useState('');
  const [suggestionPopOpen, setSuggestionPopOpen] = React.useState(false);
  const [suggestPopItems, setSuggestPopItems] = React.useState([]);
  const [popSpellingErrorsOpen, setPopSpellingErrorsOpen] = React.useState(false);
  const [scoreInfoSP, setScoreInfoSP] = React.useState({});
  const [popGMErrorsOpen, setPopGMErrorsOpen] = React.useState(false);
  const [scoreInfoGM, setScoreInfoGM] = React.useState({});
  const [scoreInfoVC, setScoreInfoVC] = React.useState({});
  const [scoreInfoVCX, setScoreInfoVCX] = React.useState({});
  const [essayLog, setEssayLog] = React.useState({});
  const [popVCErrorsOpen, setPopVCErrorsOpen] = React.useState(false);
  const [popVCXOpen, setPopVCXOpen] = React.useState(false);
  const [popShowLogOpen, setPopShowLogOpen] = React.useState(false);
  const [popShowFeedbackOpen, setPopShowFeedbackOpen] = useState(false);
  const [popShareOpen, setPopShareOpen] = React.useState(false);
  const [feedbackData, setFeedbackData] = useState({
	  text: '',
	  files: [],
	  type: '',
	});
  const [recVersion, setRecVersion] = useState(1);
  const gradeList = [
    { v: 'P1', n: 'PRIMARY 1'},
    { v: 'P2', n: 'PRIMARY 2'},
    { v: 'P3', n: 'PRIMARY 3'},
    { v: 'P4', n: 'PRIMARY 4'},
    { v: 'P5', n: 'PRIMARY 5'},
    { v: 'P6', n: 'PRIMARY 6'}
  ];
  const [isOK_GM, setIsOK_GM] = useState(false);
  const [isOK_SP, setIsOK_SP] = useState(false);
  const [isOK_VC, setIsOK_VC] = useState(false);
  const [isOK_ST, setIsOK_ST] = useState(false);
  const [popCmtOpen, setPopCmtOpen] = useState(false);
  const [popCmtType, setPopCmtType] = useState('');
  const [popCmtText, setPopCmtText] = useState('');
  const [comments, setComments] = useState({
    GM: {list: []},
    SP: {list: []},
    VC: {list: []},
    ST: {list: []},
    OA: {list: []},
  });
  const [iamTEA, setIamTEA] = useState(false);
  const [isResubmitting, setIsResubmitting] = useState(false);
  const [startCheckStatus, setStartCheckStatus] = useState(false);
  const [popInprogressOpen, setPopInprogressOpen] = useState(false);
  const [popResubmitOpen, setPopResubmitOpen] = useState(false);
  const [popErrorAddOpen, setPopErrorAddOpen] = useState(false);
  const [popErrorAddWords, setPopErrorAddWords] = useState('');
  const [popErrorAddStartIndex, setPopErrorAddStartIndex] = useState(-1);
  const [popErrorAddLine, setPopErrorAddLine] = useState('');
  const [popScoreOpen, setPopScoreOpen] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);

  const {essayList, isEssayListLoaded, loadEssayList, essaySelect} = useEssayContext();
  
  useEffect(() => {
    if (popShowFeedbackOpen) {
      setFeedbackData({
        text: '',
        files: [],
        type: '',
      });
    }
  }, [popShowFeedbackOpen]);

  const scoreCodes = [
    { c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'ST', c2: 'structure', n:'Structure', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'FL', c2: 'flow', n:'Flow', v:0, p:0 },
    //{ c:'OA', n:'Overall', v:2, p:40 },
  ];
  const scoreCodesV2 = [
    { c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'ST', c2: 'structure_and_flow', n:'Structure and Flow', v:0, p:0, eg: [], sub: [], expand: false }
  ];
  const scoreCodesV4 = [
    { c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'ST', c2: 'structure_and_flow', n:'Structure and Flow', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'CT', c2: 'content', n:'Content', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'CR', c2: 'content_relevance', n:'Content Relevance', v:0, p:0, eg: [], sub: [], expand: false }, 
  ];
  const [scoreResult, setScoreResult] = React.useState({});
  const [recGrade, setRecGrade] = React.useState('');
  
  const onScan = async() => {
	  setPopupOpen(true);
  };
  
  const [contextMenu, setContextMenu] = React.useState(null);
  const [totalSelectedWords, setTotalSelectedWords] = useState(0);
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);

  const handleMouseMove = (event) => {
    setX(event.clientX);
    setY(event.clientY);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
	 setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleClose = () => {
	  var selectedText = window.getSelection().toString();;
	 
    setContextMenu(null);
  };
  
  let selectionTimeout;

	const handleSelectionChange = (event) => {
		
	  
	
	  const selection = window.getSelection();
	  const selectedText = selection.toString().trim();

	  if (selectedText.length === 0) {
		// No text is selected, close the context menu
		clearTimeout(selectionTimeout);
		setContextMenu(null);
		return;
	  }

	  const words = selectedText.split(/\s+/);
	  setTotalSelectedWords(words.length);
	 
	  // Clear any previous timeout
	  clearTimeout(selectionTimeout);

	  // Set a timeout to show the context menu after 2 seconds
	  selectionTimeout = setTimeout(() => {
		setContextMenu({
		  mouseX: x + 100,
		  mouseY: y + 200,
		});
	  }, 2000);
	};
  
  const handleFeedbackSubmit = async () => {
    if(feedbackData.text == ""){
      alert('Feedback is required');
      return;
    }
    if(feedbackData.type == ""){
      alert('Feedback type is required');
      return;
    }
	  const images = [];
	  var _url = 'api/feedback';
    var _user = AppHelper.userCacheGet();
				
	  if(feedbackData.files.length == 0)
	  {
		  
		var _res = await AppHelper.apiPost(_url, {token: _user.token, b64: images, essayId:recEssay.essayId, feedback:feedbackData.text, feedbackType:feedbackData.type });
			
			if(!_res || !_res.status){
			  alert('ERROR: Failed to submit feedback!');			  
			  return;
			}
			else
			{
			  setFeedbackData({
				  text: '',
				  files: [],
				  type: '',
				});
				alert('Feedback submitted!');	
			  setPopShowFeedbackOpen(false);
			}
	  }
	  else
	  {
		  
		  let totalSize = 0;

			// Calculate total size of all files
			for (let i = 0; i < feedbackData.files.length; i++) {
				totalSize += feedbackData.files[i].size;
			}

			// Check if total size exceeds 9MB (9 * 1024 * 1024 bytes)
			if (totalSize > 9 * 1024 * 1024) {
				alert('Total size of uploaded files cannot exceed 9MB.');
				return;
			}
	  
		  for (let i = 0; i < feedbackData.files.length; i++) {
			const reader = new FileReader();
			reader.readAsDataURL(feedbackData.files[i]);
			reader.onload = async () => {
			  images.push(reader.result.split(',')[1]);
			  if (images.length === feedbackData.files.length) {
					const feedbackWithImages = { ...feedbackData, images };
					
					var _res = await AppHelper.apiPost(_url, {token: _user.token, b64: images, essayId:recEssay.essayId, feedback:feedbackData.text, feedbackType:feedbackData.type });
					
					if(!_res || !_res.status){
					  alert('ERROR: Failed to submit feedback!');			  
					  return;
					}
					else
					{
					  setFeedbackData({
						  text: '',
						  files: [],
						  type: '',
						});
						alert('Feedback submitted!');	
					  setPopShowFeedbackOpen(false);			  
					}
				 
				}
			};
		  }
	  }
  };
  const commentsLoad = async(argEssayId)=>{
    var _user = AppHelper.userCacheGet();
    if(!_user) _user = {token:'NA'};
    setComments([]);
    var _res = await AppHelper.apiPost('api/comment/list', {token: _user.token, essayId:argEssayId});
    if(!_res.status){
      return;
    }
    var _comments = {
      GM: {list: []},
      SP: {list: []},
      VC: {list: []},
      ST: {list: []},
      OA: {list: []},
    }
    _res.data.forEach(c=>{
      if(_comments[c.commentType]){
        _comments[c.commentType].list.push(c);
      }
    });
    setComments(_comments);
  }
  const onTextSelectionChange = (arg)=>{
    console.log('box - selectionchange', arg);
  }
  useEffect(()=>{
    const boxView = document.getElementById('essayViewBox');
    if(boxView){
      boxView.addEventListener('mouseup', onWordSelect);
      //boxView.addEventListener('touchend', onWordSelect);
      //boxView.addEventListener('selectionchange', onTextSelectionChange);
    }
	  //document.addEventListener('mousemove', onWordSelect); //handleMouseMove
	  //document.addEventListener('selectionchange', onWordSelect);//handleSelectionChange
    //fetchUserDetail();

    return()=>{
      if(boxView){
        boxView.removeEventListener('mouseup', onWordSelect);
      }
    };
  }, [isLoading]);
  useEffect(() => {
    setStartCheckStatus(false);
    fetchUserDetail();
    const controller = new AbortController();
    if(props.essayId){
      const fetchSignal = controller.signal;
      fetchEssayDetail(props.essayId, fetchSignal);
    }

    return () => {
      controller.abort();
    };
  }, [props.essayId]);
  const fetchUserDetail = ()=>{
    var _user = AppHelper.userCacheGet();
    if(!_user){
      //return;
      _user = {token:'NA'};
    }
    setAuthOK(_user != null);
    setOUser(_user);
    setIamTEA(_user.accountType == 'TEA');
  }
  const fetchEssayDetail = async(argId, fetchSignal)=>{
    var _user = AppHelper.userCacheGet();
    if(!_user){
      //alert('Failed to load user info!');
      //return;
      _user = {token:'NA'};
    }
    const _isTEA = _user.accountType == 'TEA';
    setIsLoading(true);
    setRecGrade('');
    setRecVersion(1);
    setRecEssayC('');
    var _res = await AppHelper.apiPost('api/essay/detail', {token: _user.token, essayId: argId}, fetchSignal);
    var _resLog = await AppHelper.apiPost('api/log/essay', {token: _user.token, essayId: argId}, fetchSignal);
    if(!_res || !_res.status || !_res.data){
      setIsLoading(false);
      //alert('ERROR: Failed to load the Essay Details!');
      console.log('ERROR: Failed to load the Essay Details!');
      return;
    }
    console.log('essay detail >>>', _res);
    setRecEssay({});
    if(_res.data.status < 5 && _res.data.status > 0){ // inprogress
      if(_res.score && _res.score.length>0 && (_res.score[0].version == '1.00')){
        //-- skip for score v1.00 - old essays
      }
      else{ 
        var _diffHours = dayjs.utc().diff(dayjs(_res.data.timestampUtc), 'hour');
        if(_diffHours>1){
          console.log('resubmit', _res.data.status, _diffHours);
          setPopResubmitOpen(true);
        }
        else{
          setPopInprogressOpen(true);
          console.log('inprogress', dayjs.utc().format('YYMMDD-HHmmss'), dayjs(_res.data.timestampUtc).format('YYMMDD-HHmmss'), _diffHours, _res.data);
          setRecEssay({essayId:_res.data.essayId});
          return;
        }
      }
    }
    if(_resLog) setEssayLog(_resLog.message);
    var _rulesJson = await EssayHelper.rulesJsonByProfileId(_user.token, _res.data.profileId, _res.data.grade);
    var _rec = EssayHelper.essayInfoGet(_res, _rulesJson);
    _rec.scores.forEach(_scTmp=>{
      _scTmp.eg = [];
      _scTmp.sub = [];
    });
    console.log('_recNew', _rec);
    // var _rec = {
    //   essayId: _res.data.essayId, profileId: _res.data.profileId, topic:_res.data.topic, grade:_res.data.grade, 
    //   text: _res.text, //_res.correctionResult ? _res.correctionResult : 'NA',
    //   scores: [...scoreCodes],
    //   email:_res.email,
	  //   student:_res.student,
    //   suggestions: [],//[...scoreResult.suggestions],
    //   avgScoreP: 0,
    //   imgs:[],
    // };
    var _needResubmit = false;
    if(_res.scoreResultGM && _res.scoreResultGM.length>0) setIsOK_GM(true);
    else{
      setIsOK_GM(false);
      _needResubmit = true;
    }
    if(_res.scoreResultSP && _res.scoreResultSP.length>0) setIsOK_SP(true);
    else{
      setIsOK_SP(false);
      _needResubmit = true;
    }
    if(_res.scoreResultVC && _res.scoreResultVC.length>0) setIsOK_VC(true);
    else{
      setIsOK_VC(false);
      _needResubmit = true;
    }
    if(_res.scoreResultST && _res.scoreResultST.length>0) setIsOK_ST(true);
    else{
      setIsOK_ST(false);
      _needResubmit = true;
    }
    if(_needResubmit){
      console.log('resubmit', _res, _needResubmit);
      setPopResubmitOpen(true);
    }
    else console.log('no-resubmit', _res, _needResubmit);

    _rec.version = 1;
    if(_res.imgs)
      _rec.imgs = _res.imgs;
    if(_res.score && _res.score.length>0 && _res.score[0].version == '2.00'){
      _rec.version = 2;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '2.10'){
      _rec.version = 2.1;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '2.20'){
      _rec.version = 2.2;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '3.00'){
      _rec.version = 3.0;
      //_rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '3.10'){
      _rec.version = 3.1;
      //_rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '4.00'){
      _rec.version = 4.0;
      //_rec.scores = [...scoreCodesV4];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(!_res.score || _res.score.length<1){
      _rec.version = 3.1;
    }
    console.log('version', _rec.version, _rec.scores);
    
    setGrade(_res.data.grade);
    var _grade = gradeList.find(g=>g.v == _res.data.grade);
    if(_grade)
      setRecGrade(_grade.n);
    //setGrade('P4');
    if(_res.correctionResult && _res.correctionResult != 'NA'){
      try{
        var _jsonStr = _res.correctionResult.replace(/({\r\n|{\n|{\r)/gm, "{");
        _jsonStr = _jsonStr.replace(/(\r\n}|\n}|\r})/gm, "}");
        _jsonStr = _jsonStr.replace(/(\r\n|\n|\r)/gm, "\\n");

        var _jsonCorr = JSON.parse(_jsonStr);
        //var _jsonCorr = JSON.parse(_res.correctionResult);
        setRecEssayC(_jsonCorr.correctedEssay);
      }
      catch(eCorr){
        console.log(eCorr);
        //console.log('error-json', _res.correctionResult.replace(/(\r\n|\n|\r)/gm, ""));
        setRecEssayC(_res.correctionResult);
      }
    }

    if(_rec.version == 2 || _rec.version == 2.1 || _rec.version == 2.2){
      setRecVersion(_rec.version);
      var _scTotal = 0;
      // const sum = arr.reduce((total, current) => total + current.v, 0);
      // const average = sum / arr.length;
      _rec.scores.forEach(_sc=>{
        _sc.eg = [];
        var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == _sc.c);
        const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
        // _sc.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
        // _sc.v = parseFloat(_sc.v.toFixed(2));
        _sc.p = (_sc.v/4)*100;
        _scTotal += _sc.v;
        var _rsCode = `scoreResult${_sc.c}`;
        if(_res[_rsCode]){
          var _jsonSR = JSON.parse(_res[_rsCode]);
          for(let _field in _jsonSR.scores){
            //var _subTitle = _field.toLowerCase().replace(_sc.c2 + '_and_', '');
            var _subTitle = _field.toLowerCase().replace(_sc.c2 + '_', '');
            var _arrSubTitle = _subTitle.split('_');
            _subTitle = _arrSubTitle.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
            _sc.sub.push({ title: _subTitle, ..._jsonSR.scores[_field] });
            _jsonSR.scores[_field].exampleImprovements.forEach(_rEg=>{
              _sc.eg.push(_rEg);
            });
          }
        }
      });
      _rec.avgScoreP = (_scTotal / (4*4))*100;
    }
    //else if(_rec.version == 3.0 || _rec.version == 3.1)
    else if(_rec.version >= 3){
      setRecVersion(_rec.version);
      var _scTotal = 0;
      _rec.scores.forEach(_sc=>{
        if(!_sc.eg) _sc.eg = [];
        var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == _sc.c);
        const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
        // _sc.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
        // _sc.v = parseFloat(_sc.v.toFixed(2));
        // if(_sc.c == 'ST'){
        //   _sc.v = parseFloat((_sc.v * 2.5).toFixed(2));
        // }
        _sc.p = (_sc.v/10)*100;
        
        
        var _rsCode = `scoreResult${_sc.c}`;
        if(_res[_rsCode]){
          var _jsonSR = JSON.parse(_res[_rsCode]);
          if(_sc.c == 'SP'){
            var _spErrCount = _jsonSR.errors ? _jsonSR.errors.filter(x=>x.isFalse!=1).length : 0;
            _sc.scoreInfo = {
              show: _spErrCount>0,
              title: `You made ${_spErrCount} spelling mistake${_spErrCount>1?'s':''} in your essay.`,
              subTitles: [],
              levelItems0: [],
              levelItems1: [],
              levelItems2: []
            };
            if(_jsonSR.levels){
              if(_jsonSR.errors){
                _jsonSR.errors.forEach((je,iJE)=>{
                  je.eIndex = iJE;
                });
              }
              var _spErrLevelTotal = 0;
              var _spErrLevelDifficult = 0;
              var _spErrLevelMedium = 0;
              var _spErrLevelEasy = 0;
              if(_jsonSR.levels.difficult_words){
                _spErrLevelDifficult = _jsonSR.levels.difficult_words.length;
                _spErrLevelTotal += _jsonSR.levels.difficult_words.length;
              }
              if(_jsonSR.levels.medium_words){
                _spErrLevelMedium = _jsonSR.levels.medium_words.length;
                _spErrLevelTotal += _jsonSR.levels.medium_words.length;
              }
              if(_jsonSR.levels.easy_words){
                _spErrLevelEasy = _jsonSR.levels.easy_words.length;
                _spErrLevelTotal += _jsonSR.levels.easy_words.length;
              }

              if(_spErrLevelEasy>0){
                var _percent = (_spErrLevelEasy / _spErrLevelTotal) * 100;
                _sc.scoreInfo.subTitles.push(`Easy Words: ${_percent.toFixed(2)}% of mistakes`);
              }
              if(_spErrLevelMedium>0){
                var _percent = (_spErrLevelMedium / _spErrLevelTotal) * 100;
                _sc.scoreInfo.subTitles.push(`Medium Words: ${_percent.toFixed(2)}% of mistakes`);
              }
              if(_spErrLevelDifficult>0){
                var _percent = (_spErrLevelDifficult / _spErrLevelTotal) * 100;
                _sc.scoreInfo.subTitles.push(`Difficult Words: ${_percent.toFixed(2)}% of mistakes`);
              }

              if(_jsonSR.levels.easy_words){
                _jsonSR.levels.easy_words.forEach(l=>{
                  var _arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1) && errorRec.right_spelling == l.word);
                  if(_arrWord.length<1)
                    _arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1) && errorRec.right_spelling.includes(l.word));
                  if(_arrWord.length>0 && !_sc.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)){
                    _sc.scoreInfo.levelItems0.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
                    _sc.eg.push({});
                  }
                  //_sc.scoreInfo.levelItems0.push({w: _arrWord.length>0?_arrWord[0].wrong_spelling:'-', l:'Easy', r:l.right_spelling});
                });
              }
              if(_jsonSR.levels.medium_words){
                _jsonSR.levels.medium_words.forEach(l=>{
                  var _arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1)  && errorRec.right_spelling == l.word);
                  if(_arrWord.length<1)
                    _arrWord = _jsonSR.errors.filter(errorRec=>errorRec.right_spelling.includes(l.word));
                  if(_arrWord.length>0 && !_sc.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)
                    && !_sc.scoreInfo.levelItems1.find(r=>r.w == _arrWord[0].wrong_spelling)){
                    _sc.scoreInfo.levelItems1.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
                    _sc.eg.push({});
                  }
                  // _sc.scoreInfo.levelItems1.push({w:  _arrWord.length>0?_arrWord[0].wrong_spelling:'-', l:'Medium', r:l.right_spelling});
                  // _sc.eg.push({});
                });
              }
              if(_jsonSR.levels.difficult_words){
                _jsonSR.levels.difficult_words.forEach(l=>{
                  var _arrWord = _jsonSR.errors.filter(errorRec=> errorRec.isFalse!=1 && errorRec.right_spelling == l.word);
                  if(_arrWord.length<1)
                    _arrWord = _jsonSR.errors.filter(errorRec=>errorRec.right_spelling && errorRec.right_spelling.includes(l.word));
                  if(_arrWord.length>0 && !_sc.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)
                    && !_sc.scoreInfo.levelItems1.find(r=>r.w == _arrWord[0].wrong_spelling)
                    && !_sc.scoreInfo.levelItems2.find(r=>r.w == _arrWord[0].wrong_spelling)){
                    _sc.scoreInfo.levelItems2.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
                    _sc.eg.push({});
                  }
                  // _sc.scoreInfo.levelItems2.push({w:  _arrWord.length>0?_arrWord[0].wrong_spelling:'-', l:'Difficult', r:l.right_spelling});
                  // _sc.eg.push({});
                });
              }
            }
          }
          else if(_sc.c == 'GM'){
            var _gmErrCount = _jsonSR.errors ? _jsonSR.errors.filter(x=>x.isFalse!=1).length : 0;
            _sc.scoreInfo = {
              show: _gmErrCount>0,
              title: `You made ${_gmErrCount} grammar mistake${_gmErrCount>1?'s':''} in your essay.`,
              subTitles: [],
              errors: _jsonSR.errors ? [..._jsonSR.errors] : [],
              suggestions: _jsonSR.suggestions ? [..._jsonSR.suggestions] : [],
              levelItems1: []
            };
            _sc.scoreInfo.errors.forEach(r=>{
              _sc.eg.push({eg_gm:'eg_gm'});
              var _arrSug = _sc.scoreInfo.suggestions.filter(sg=>sg.text == r.w && sg.issues && sg.issues.length>0 && sg.issues[0].type != 'NA');
              if(_arrSug.length>0){
                r.type = titleAsReadable(_arrSug[0].issues[0].type, '-');
                r.subType = titleAsReadable(_arrSug[0].issues[0].subType, '-');
                r.r = _arrSug[0].issues[0].corrected_sentence;
              }
            });
            _sc.scoreInfo.errors = _sc.scoreInfo.errors.filter(r=>r.type != 'None');
          }
          else if(_sc.c == 'VC'){
            var _scVCX = _arrScRec.find(rVCX=>rVCX.subScoreCode=='VCX');
            var _scVCA = _arrScRec.find(rVCX=>rVCX.subScoreCode=='VCA');
            var _isVCALL = _scVCX == null || _scVCA == null;
            var _vcErrCount = _jsonSR.errors ? _jsonSR.errors.length : 0;
            var _vScoreOrigin = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
            _sc.scoreInfo = {
              show: _vcErrCount>0,
              title: `You made ${_vcErrCount} vocabulary mistake${_vcErrCount>1?'s':''} in your essay.`,
              subTitles: [],
              errors: _jsonSR.errors ? [..._jsonSR.errors] : [],
              suggestions: _jsonSR.suggestions ? [..._jsonSR.suggestions] : [],
              levelItems1: [],
              rareWords: [],
              blocks: [
                {
                  title: `${_vScoreOrigin}/10 Vocabulary Appropriateness`,
                  subTitles: [`You made ${_vcErrCount} vocabulary mistake${_vcErrCount>1?'s':''} in your essay.`] 
                },
                {title: `0/10 Vocabulary Complexity`, subTitles: [`0 uncommon words used.`, `0 rare words used.`]}
              ]
            };
            _sc.scoreInfo.errors.forEach(r=>{
              _sc.eg.push({});
            });
            var _scoreVCA = 10;
            if(_sc.scoreInfo.errors.length<1){
              //_sc.v = 10;
              _sc.scoreInfo.blocks[0].hide = true;
            }
            var _vcxWordPoints = 0;
            var _block1SubTitles = [];
            _sc.scoreInfo.vcxPoint = 0;

            if(_res.scoreResultVCX){
              var _jsonVCX = JSON.parse(_res.scoreResultVCX);
              if(_jsonVCX && _jsonVCX.res){
               
                if(_jsonVCX.res.uk_uncommon_words){
                  _sc.scoreInfo.blocks[1].subTitles[0] = `${_jsonVCX.res.uk_uncommon_words.filter(r=>r.isFalse!=1).length} uncommon words used.`;
                  _jsonVCX.res.uk_uncommon_words.forEach((wc,iWC)=>{
                    _sc.scoreInfo.rareWords.push({w: wc.original_word, t:'U', iWord:iWC, isFalse:wc.isFalse});
                  })
                  _vcxWordPoints += _jsonVCX.res.uk_uncommon_words.length * 0.5;
                }
                
                if(_jsonVCX.res.uk_rare_words){
                  _sc.scoreInfo.blocks[1].subTitles[1] = `${_jsonVCX.res.uk_rare_words.filter(r=>r.isFalse!=1).length} rare words used.`;
                  _jsonVCX.res.uk_rare_words.forEach((wc, iWC)=>{
                    _sc.scoreInfo.rareWords.push({w: wc.original_word, t:'R', iWord:iWC, isFalse:wc.isFalse});
                  })
                  _vcxWordPoints += _jsonVCX.res.uk_rare_words.length * 1;
                }                  
              }
            }

            if(_isVCALL){
              if(_sc.scoreInfo.errors.length>0){
                var _wordsCount = wordCount(_rec.text);
                var _errorWordCountPercent = Math.ceil((_sc.scoreInfo.errors.length / _wordsCount)*100);
                if(_errorWordCountPercent>=9) _scoreVCA = 1;
                else if(_errorWordCountPercent>=8) _scoreVCA = 2;
                else if(_errorWordCountPercent>=7) _scoreVCA = 3;
                else if(_errorWordCountPercent>=6) _scoreVCA = 4;
                else if(_errorWordCountPercent>=5) _scoreVCA = 5;
                else if(_errorWordCountPercent>=4) _scoreVCA = 6;
                else if(_errorWordCountPercent>=3) _scoreVCA = 7;
                else if(_errorWordCountPercent>=2) _scoreVCA = 8;
                else if(_errorWordCountPercent>=1) _scoreVCA = 9;
              }

              if(_vcxWordPoints>0){  
                var _vcxPoint = 1;
                if(_vcxWordPoints>=5) _vcxPoint = 10;
                else if(_vcxWordPoints>=4.5) _vcxPoint = 9;
                else if(_vcxWordPoints>=4) _vcxPoint = 8;
                else if(_vcxWordPoints>=3.5) _vcxPoint = 7;
                else if(_vcxWordPoints>=3) _vcxPoint = 6;
                else if(_vcxWordPoints>=2.5) _vcxPoint = 5;
                else if(_vcxWordPoints>=2) _vcxPoint = 4;
                else if(_vcxWordPoints>=1.5) _vcxPoint = 3;
                else if(_vcxWordPoints>=1) _vcxPoint = 2;

                //console.log('scoreVC:' + _sc.v);
                //console.log(`Words:${_wordsCount}, ERROR:${_errorWordCountPercent}%, scoreVCA:${_scoreVC}`);
                _sc.v = (_scoreVCA + (_vcxPoint)) / 2;
                _sc.p = (_sc.v/10)*100;
                //console.log(`VCXWordPoints:${_vcxWordPoints}, VCXPoint:${_vcxPoint}, scoreVC:${_sc.v}`);
                _sc.scoreInfo.vcxPoint = _vcxPoint;
              }
              
              // if(_sc.eg.length<1 && _vcxPoint>1){
              //   _sc.eg.push({});
              // }
              // else{
              //   if(_sc.eg.length<1){
              //     console.log('_sc.eg', _sc.eg, _vcxPoint);
              //   }
              // }
            }
            else{ // VCA & VCX
              _scoreVCA = _scVCA.score;
              _sc.scoreInfo.vcxPoint = _scVCX.score;
            }
            _sc.scoreInfo.blocks[0].title = `${_scoreVCA}/10 Vocabulary Appropriateness`;
            _sc.scoreInfo.blocks[1].title = `${_sc.scoreInfo.vcxPoint}/10 Vocabulary Complexity`;
            _sc.v = (_scoreVCA + (_sc.scoreInfo.vcxPoint)) / 2;
            _sc.p = (_sc.v/10)*100;
            if(_sc.eg.length<1 && _sc.scoreInfo.vcxPoint>1){
              _sc.eg.push({});
            }
            // console.log('sub code', _sc, _arrScRec);
          }
          else if(_sc.c == 'CT'){
            console.log('>>>>> CT <<<<<', _sc);
          }
          else{
            for(let _field in _jsonSR.scores){
              //var _subTitle = _field.toLowerCase().replace(_sc.c2 + '_and_', '');
              var _subTitle = _field.toLowerCase().replace(_sc.c2 + '_', '');
              var _arrSubTitle = _subTitle.split('_');
              _subTitle = _arrSubTitle.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
              _sc.sub.push({ title: _subTitle, ..._jsonSR.scores[_field] });
              _jsonSR.scores[_field].exampleImprovements.forEach(_rEg=>{
                _sc.eg.push(_rEg);
              });
            }
          }
          console.log('>>> SC <<<', _sc);
        }
        _scTotal += _sc.v;
      });
      //_rec.avgScoreP = (_scTotal / (10*4))*100;
      _rec.avgScoreP = _rec.avgScoreP_r;
    }
    else{
      var _scoreResult = (_res.scoreResult && _res.scoreResult != '' && _res.scoreResult != 'NA') ? JSON.parse(_res.scoreResult) : null;
      if(_scoreResult){
        setScoreResult({..._scoreResult});
        if(_scoreResult.suggestions)
          _rec.suggestions = [..._scoreResult.suggestions];
        else if(_scoreResult.scores && _scoreResult.scores.suggestions)
          _rec.suggestions = [..._scoreResult.scores.suggestions];
        if(_scoreResult.scores){
          var _scTotal = 0;
          _rec.scores.forEach(sc=>{
            var _sc = _scoreResult.scores[sc.c2];
            if(_sc){
              sc.v = _sc.score;
              _scTotal += _sc.score;
              if(sc.v>0){
                sc.p = (sc.v/5)*100;
              }
              sc.eg = _sc.exampleImprovements;
            }
          });
          _rec.avgScoreP = (_scTotal / (5*5))*100;
        }
      }
    }
    setWordsCount(wordCount(_rec.text));
  
    if((scroll2End) && _rec.scores){
      _rec.scores.forEach(rs=>{
        rs.expand = true;
      });
    }
    setRecEssay(_rec);
    setTimeout(() => {
      setWordsCount2(wordCount(recEssayC));
    }, 500);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000*0.5);
    commentsLoad(_rec.essayId);
  };
  const fetchEssayDetail_sync = async(argId)=>{
    var _user = AppHelper.userCacheGet();
    if(!_user){
      alert('Failed to load user info!');
      return;
    }
    const _isTEA = _user.accountType == 'TEA';
    var _res = await AppHelper.apiPost('api/essay/detail', {token: _user.token, essayId: argId});
    if(!_res || !_res.status || !_res.data){
      console.log('ERROR: Failed to load the Essay Details!');
      return;
    }
    console.log('essay detail - sync', _res);
    var _rec = {
      essayId: _res.data.essayId, profileId: _res.data.profileId, topic:_res.data.topic, grade:_res.data.grade, 
      text: _res.text, //_res.correctionResult ? _res.correctionResult : 'NA',
      scores: [...scoreCodes],
      email:_res.email,
	    student:_res.student,
      suggestions: [],//[...scoreResult.suggestions],
      avgScoreP: 0,
      imgs:[],
    };
    _rec.version = 1;
    if(_res.imgs)
      _rec.imgs = _res.imgs;
    if(_res.score && _res.score.length>0 && _res.score[0].version == '2.00'){
      _rec.version = 2;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '2.10'){
      _rec.version = 2.1;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '2.20'){
      _rec.version = 2.2;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '3.00'){
      _rec.version = 3.0;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '3.10'){
      _rec.version = 3.1;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(_res.score && _res.score.length>0 && _res.score[0].version == '4.0'){
      _rec.version = 4.0;
      _rec.scores = [...scoreCodesV2];// [..._rec.scores.filter(rs=>rs.c != 'FL')];
    }
    else if(!_res.score || _res.score.length<1){
      _rec.version = 3.1;
    }
    setRecVersion(_rec.version);
    var _scTotal = 0;
    // const sum = arr.reduce((total, current) => total + current.v, 0);
    // const average = sum / arr.length;
    _rec.scores.forEach(_sc=>{
      _sc.eg = [];
      var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == _sc.c);
      const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
      _sc.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
      _sc.v = parseFloat(_sc.v.toFixed(2));

      // if(_sc.c == 'SP' || _sc.c == 'GM' || _sc.c == 'VC'){
      //  _sc.v = _sc.v / 2.5; 
      // }
      // _sc.p = (_sc.v/4)*100;
      if(_sc.c == 'ST'){
        _sc.v = parseFloat((_sc.v * 2.5).toFixed(2));
      }
      _sc.p = (_sc.v/10)*100;
      
      
      var _rsCode = `scoreResult${_sc.c}`;
      if(_res[_rsCode]){
        var _jsonSR = JSON.parse(_res[_rsCode]);
        if(_sc.c == 'SP'){
          var _spErrCount = _jsonSR.errors ? _jsonSR.errors.filter(x=>x.isFalse!=1).length : 0;
          _sc.scoreInfo = {
            show: _spErrCount>0,
            title: `You made ${_spErrCount} spelling mistake${_spErrCount>1?'s':''} in your essay.`,
            subTitles: [],
            levelItems0: [],
            levelItems1: [],
            levelItems2: []
          };
          if(_jsonSR.levels){
            if(_jsonSR.errors){
              _jsonSR.errors.forEach((je,iJE)=>{
                je.eIndex = iJE;
              });
            }
            var _spErrLevelTotal = 0;
            var _spErrLevelDifficult = 0;
            var _spErrLevelMedium = 0;
            var _spErrLevelEasy = 0;
            if(_jsonSR.levels.difficult_words){
              _spErrLevelDifficult = _jsonSR.levels.difficult_words.length;
              _spErrLevelTotal += _jsonSR.levels.difficult_words.length;
            }
            if(_jsonSR.levels.medium_words){
              _spErrLevelMedium = _jsonSR.levels.medium_words.length;
              _spErrLevelTotal += _jsonSR.levels.medium_words.length;
            }
            if(_jsonSR.levels.easy_words){
              _spErrLevelEasy = _jsonSR.levels.easy_words.length;
              _spErrLevelTotal += _jsonSR.levels.easy_words.length;
            }

            if(_spErrLevelEasy>0){
              var _percent = (_spErrLevelEasy / _spErrLevelTotal) * 100;
              _sc.scoreInfo.subTitles.push(`Easy Words: ${_percent.toFixed(2)}% of mistakes`);
            }
            if(_spErrLevelMedium>0){
              var _percent = (_spErrLevelMedium / _spErrLevelTotal) * 100;
              _sc.scoreInfo.subTitles.push(`Medium Words: ${_percent.toFixed(2)}% of mistakes`);
            }
            if(_spErrLevelDifficult>0){
              var _percent = (_spErrLevelDifficult / _spErrLevelTotal) * 100;
              _sc.scoreInfo.subTitles.push(`Difficult Words: ${_percent.toFixed(2)}% of mistakes`);
            }

            if(_jsonSR.levels.easy_words){
              _jsonSR.levels.easy_words.forEach(l=>{
                var _arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1) && errorRec.right_spelling == l.word);
                if(_arrWord.length<1)
                  _arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1) && errorRec.right_spelling.includes(l.word));
                console.log('esay', l, _arrWord, _jsonSR.errors);
                if(_arrWord.length>0 && !_sc.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)){
                  _sc.scoreInfo.levelItems0.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
                  _sc.eg.push({});
                }
                //_sc.scoreInfo.levelItems0.push({w: _arrWord.length>0?_arrWord[0].wrong_spelling:'-', l:'Easy', r:l.right_spelling});
              });
            }
            if(_jsonSR.levels.medium_words){
              _jsonSR.levels.medium_words.forEach(l=>{
                var _arrWord = _jsonSR.errors.filter(errorRec=> (_isTEA || errorRec.isFalse!=1)  && errorRec.right_spelling == l.word);
                if(_arrWord.length<1)
                  _arrWord = _jsonSR.errors.filter(errorRec=>errorRec.right_spelling.includes(l.word));
                if(_arrWord.length>0 && !_sc.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)
                  && !_sc.scoreInfo.levelItems1.find(r=>r.w == _arrWord[0].wrong_spelling)){
                  _sc.scoreInfo.levelItems1.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
                  _sc.eg.push({});
                }
                // _sc.scoreInfo.levelItems1.push({w:  _arrWord.length>0?_arrWord[0].wrong_spelling:'-', l:'Medium', r:l.right_spelling});
                // _sc.eg.push({});
              });
            }
            if(_jsonSR.levels.difficult_words){
              _jsonSR.levels.difficult_words.forEach(l=>{
                var _arrWord = _jsonSR.errors.filter(errorRec=> errorRec.isFalse!=1 && errorRec.right_spelling == l.word);
                if(_arrWord.length<1)
                  _arrWord = _jsonSR.errors.filter(errorRec=>errorRec.right_spelling && errorRec.right_spelling.includes(l.word));
                if(_arrWord.length>0 && !_sc.scoreInfo.levelItems0.find(r=>r.w == _arrWord[0].wrong_spelling)
                  && !_sc.scoreInfo.levelItems1.find(r=>r.w == _arrWord[0].wrong_spelling)
                  && !_sc.scoreInfo.levelItems2.find(r=>r.w == _arrWord[0].wrong_spelling)){
                  _sc.scoreInfo.levelItems2.push({w: _arrWord[0].wrong_spelling, l:'Easy', r:_arrWord[0].right_spelling, f:_arrWord[0].flag, isFalse: _arrWord[0].isFalse, eIndex: _arrWord[0].eIndex});
                  _sc.eg.push({});
                }
                // _sc.scoreInfo.levelItems2.push({w:  _arrWord.length>0?_arrWord[0].wrong_spelling:'-', l:'Difficult', r:l.right_spelling});
                // _sc.eg.push({});
              });
            }
          }
        }
        else if(_sc.c == 'GM'){
          var _gmErrCount = _jsonSR.errors ? _jsonSR.errors.filter(x=>x.isFalse!=1).length : 0;
          _sc.scoreInfo = {
            show: _gmErrCount>0,
            title: `You made ${_gmErrCount} grammar mistake${_gmErrCount>1?'s':''} in your essay.`,
            subTitles: [],
            errors: _jsonSR.errors ? [..._jsonSR.errors] : [],
            suggestions: _jsonSR.suggestions ? [..._jsonSR.suggestions] : [],
            levelItems1: []
          };
          _sc.scoreInfo.errors.forEach(r=>{
            _sc.eg.push({});
            var _arrSug = _sc.scoreInfo.suggestions.filter(sg=>sg.text == r.w && sg.issues && sg.issues.length>0 && sg.issues[0].type != 'NA');
            if(_arrSug.length>0){
              r.type = titleAsReadable(_arrSug[0].issues[0].type, '-');
              r.subType = titleAsReadable(_arrSug[0].issues[0].subType, '-');
              r.r = _arrSug[0].issues[0].corrected_sentence;
            }
          });
          _sc.scoreInfo.errors = _sc.scoreInfo.errors.filter(r=>r.type != 'None');
        }
        else if(_sc.c == 'VC'){
          var _scVCX = _arrScRec.find(rVCX=>rVCX.subScoreCode=='VCX');
          var _scVCA = _arrScRec.find(rVCX=>rVCX.subScoreCode=='VCA');
          var _isVCALL = _scVCX == null || _scVCA == null;
          var _vcErrCount = _jsonSR.errors ? _jsonSR.errors.length : 0;
          var _vScoreOrigin = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
          _sc.scoreInfo = {
            show: _vcErrCount>0,
            title: `You made ${_vcErrCount} vocabulary mistake${_vcErrCount>1?'s':''} in your essay.`,
            subTitles: [],
            errors: _jsonSR.errors ? [..._jsonSR.errors] : [],
            suggestions: _jsonSR.suggestions ? [..._jsonSR.suggestions] : [],
            levelItems1: [],
            rareWords: [],
            blocks: [
              {
                title: `${_vScoreOrigin}/10 Vocabulary Appropriateness`,
                subTitles: [`You made ${_vcErrCount} vocabulary mistake${_vcErrCount>1?'s':''} in your essay.`] 
              },
              {title: `0/10 Vocabulary Complexity`, subTitles: [`0 uncommon words used.`, `0 rare words used.`]}
            ]
          };
          _sc.scoreInfo.errors.forEach(r=>{
            _sc.eg.push({});
          });
          var _scoreVCA = 10;
          if(_sc.scoreInfo.errors.length<1){
            //_sc.v = 10;
            _sc.scoreInfo.blocks[0].hide = true;
          }
          var _vcxWordPoints = 0;
          var _block1SubTitles = [];
          _sc.scoreInfo.vcxPoint = 0;

          if(_res.scoreResultVCX){
            var _jsonVCX = JSON.parse(_res.scoreResultVCX);
            if(_jsonVCX && _jsonVCX.res){
              
              if(_jsonVCX.res.uk_uncommon_words){
                _sc.scoreInfo.blocks[1].subTitles[0] = `${_jsonVCX.res.uk_uncommon_words.filter(r=>r.isFalse!=1).length} uncommon words used.`;
                _jsonVCX.res.uk_uncommon_words.forEach((wc,iWC)=>{
                  _sc.scoreInfo.rareWords.push({w: wc.original_word, t:'U', iWord:iWC, isFalse:wc.isFalse});
                })
                _vcxWordPoints += _jsonVCX.res.uk_uncommon_words.length * 0.5;
              }
              
              if(_jsonVCX.res.uk_rare_words){
                _sc.scoreInfo.blocks[1].subTitles[1] = `${_jsonVCX.res.uk_rare_words.filter(r=>r.isFalse!=1).length} rare words used.`;
                _jsonVCX.res.uk_rare_words.forEach((wc,iWC)=>{
                  _sc.scoreInfo.rareWords.push({w: wc.original_word, t:'R', iWord:iWC, isFalse:wc.isFalse});
                })
                _vcxWordPoints += _jsonVCX.res.uk_rare_words.length * 1;
              }                  
            }
          }

          if(_isVCALL){
            if(_sc.scoreInfo.errors.length>0){
              var _wordsCount = wordCount(_rec.text);
              var _errorWordCountPercent = Math.ceil((_sc.scoreInfo.errors.length / _wordsCount)*100);
              if(_errorWordCountPercent>=9) _scoreVCA = 1;
              else if(_errorWordCountPercent>=8) _scoreVCA = 2;
              else if(_errorWordCountPercent>=7) _scoreVCA = 3;
              else if(_errorWordCountPercent>=6) _scoreVCA = 4;
              else if(_errorWordCountPercent>=5) _scoreVCA = 5;
              else if(_errorWordCountPercent>=4) _scoreVCA = 6;
              else if(_errorWordCountPercent>=3) _scoreVCA = 7;
              else if(_errorWordCountPercent>=2) _scoreVCA = 8;
              else if(_errorWordCountPercent>=1) _scoreVCA = 9;
            }

            if(_vcxWordPoints>0){  
              var _vcxPoint = 1;
              if(_vcxWordPoints>=5) _vcxPoint = 10;
              else if(_vcxWordPoints>=4.5) _vcxPoint = 9;
              else if(_vcxWordPoints>=4) _vcxPoint = 8;
              else if(_vcxWordPoints>=3.5) _vcxPoint = 7;
              else if(_vcxWordPoints>=3) _vcxPoint = 6;
              else if(_vcxWordPoints>=2.5) _vcxPoint = 5;
              else if(_vcxWordPoints>=2) _vcxPoint = 4;
              else if(_vcxWordPoints>=1.5) _vcxPoint = 3;
              else if(_vcxWordPoints>=1) _vcxPoint = 2;

              //console.log('scoreVC:' + _sc.v);
              //console.log(`Words:${_wordsCount}, ERROR:${_errorWordCountPercent}%, scoreVCA:${_scoreVC}`);
              _sc.v = (_scoreVCA + (_vcxPoint)) / 2;
              _sc.p = (_sc.v/10)*100;
              //console.log(`VCXWordPoints:${_vcxWordPoints}, VCXPoint:${_vcxPoint}, scoreVC:${_sc.v}`);
              _sc.scoreInfo.vcxPoint = _vcxPoint;
            }
            
            // if(_sc.eg.length<1 && _vcxPoint>1){
            //   _sc.eg.push({});
            // }
            // else{
            //   if(_sc.eg.length<1){
            //     console.log('_sc.eg', _sc.eg, _vcxPoint);
            //   }
            // }
          }
          else{ // VCA & VCX
            _scoreVCA = _scVCA.score;
            _sc.scoreInfo.vcxPoint = _scVCX.score;
          }
          _sc.scoreInfo.blocks[0].title = `${_scoreVCA}/10 Vocabulary Appropriateness`;
          _sc.scoreInfo.blocks[1].title = `${_sc.scoreInfo.vcxPoint}/10 Vocabulary Complexity`;
          _sc.v = (_scoreVCA + (_sc.scoreInfo.vcxPoint)) / 2;
          _sc.p = (_sc.v/10)*100;
          if(_sc.eg.length<1 && _sc.scoreInfo.vcxPoint>1){
            _sc.eg.push({});
          }
          // console.log('sub code', _sc, _arrScRec);
        }
        else{
          for(let _field in _jsonSR.scores){
            //var _subTitle = _field.toLowerCase().replace(_sc.c2 + '_and_', '');
            var _subTitle = _field.toLowerCase().replace(_sc.c2 + '_', '');
            var _arrSubTitle = _subTitle.split('_');
            _subTitle = _arrSubTitle.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
            _sc.sub.push({ title: _subTitle, ..._jsonSR.scores[_field] });
            _jsonSR.scores[_field].exampleImprovements.forEach(_rEg=>{
              _sc.eg.push(_rEg);
            });
          }
        }
      }
      _scTotal += _sc.v;
    });
    _rec.avgScoreP = (_scTotal / (10*4))*100;
    setRecEssay(_rec);
    loadEssayList(_user);
  };
  const convertNewlinesToBr = (text) => {
    if(!text) return <React.Fragment></React.Fragment>;
	  text = text.replace(/<[^>]*>/g, '');
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };
  const titleAsReadable = (argTitle, argSplitter='_')=>{
    if(!argTitle) return '';
    var _arrSubTitle = argTitle.split(argSplitter);
    return _arrSubTitle.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  }
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const getDifferences = () => {
    if(!recEssay.text || !recEssayC) return <span></span>;
    //const differences = diffChars(recEssay.text, recEssayC);
    //const differences = diffWords(recEssay.text, recEssayC);
    const differences = diffWordsWithSpace(recEssay.text, recEssayC);
    return differences.map((part, index) => {
      // const color = part.added ? 'blue' : part.removed ? "red" : '#000';
      const style = {
        color: part.added ? '#1976d2' : part.removed ? '#ef5350' : '#000',
        // color: part.added ? '#304ffe' : part.removed ? '#d50000' : '#000',
        // backgroundColor: part.added ? '#e1f5fe' : part.removed ? '#fff3e0' : '#fff',
        // color: part.added ? '#fff' : part.removed ? '#fff' : '#000',
        // backgroundColor: part.added ? '#1976d2' : part.removed ? '#ef5350' : '#fff',
        textDecoration: part.added? 'underline' : part.removed ? 'line-through' : 'none',
        fontWeight: (part.added || part.removed) ? 'bold' : 'normal',
      };
      return <span key={index} style={ style }>{convertNewlinesToBr(part.value)}</span>;
    });
  };
  const wordCount = (_txt) =>{
    setMsgText(_txt);
    if(!_txt) return 0;
    return _txt.trim().split(/\s+/).length;
  };
  const onShowLog = async() => {
	  setPopShowLogOpen(true);  
  }
  const onShare = async() => {
	  setPopShareOpen(true);  
  }
  const onScoreView = async() => {
	  setPopScoreOpen(true);  
  }
  const onReportDownload = async() => {
    var content = "1. ### This is an Essay titled `" + recEssay.topic + "` by a `Primary " + recEssay.grade.replace("P", "") + "` student `" + recEssay.student + "` (" + recEssay.email + ").\n\n";
    const textLines = recEssay.text.split('\n');
    textLines.forEach(line => {
      content += `\n>> ${line}`;
    });
	
    content += "\n\n2. ### This is a corrected copy of the essay with corrections to _spelling_ and _grammar_, and improvements to _phrasing_.\n\n";
    
    const text = recEssayC;
    
    const lines = text.split('\n');
    lines.forEach(line => {
      content += `\n>> ${line}`;
    });
    
    content += "\n\n3. ### Score across various categories:\n\n";
    var _scoreSP = recEssay.scores.find(s=>s.c == 'SP');
	var spMistakes = 0;
	if(_scoreSP.scoreInfo.levelItems0)
	{
		spMistakes = _scoreSP.scoreInfo.levelItems0.length;
	}
	if(_scoreSP.scoreInfo.levelItems1)
	{
		spMistakes += _scoreSP.scoreInfo.levelItems1.length;
	}
	if(_scoreSP.scoreInfo.levelItems2)
	{
		spMistakes += _scoreSP.scoreInfo.levelItems2.length;
	}
	
	content += '#### 3.1) Spelling: Score '+ _scoreSP.v + "/10" + ', ' + spMistakes + ' mistakes\n';
	
    if(_scoreSP && _scoreSP.scoreInfo){
      
      if(!_scoreSP.scoreInfo.levelItems0 || _scoreSP.scoreInfo.levelItems0.length<1){
        content += '\n* **Easy Mistakes (' + 0 + ' mistakes)**:';
        content += 'NA';
      }
      else{
		content += '\n* **Easy Mistakes (' + _scoreSP.scoreInfo.levelItems0.length + ' mistakes)**:';
        content += '\n| Wrong Spelling | Correct Spelling |';
        content += '\n| :------------:|:-------------:|';
        _scoreSP.scoreInfo.levelItems0.forEach(ssp=>{
          content += `\n| ${ssp.w} | ${ssp.r} |`;
        });
      }
     
      if(!_scoreSP.scoreInfo.levelItems1 || _scoreSP.scoreInfo.levelItems1.length<1){
		  content += '\n* **Medium Mistakes (' + 0 + ' mistakes)**:';
        content += 'NA';
      }
      else{
		  content += '\n* **Medium Mistakes (' + _scoreSP.scoreInfo.levelItems1.length + ' mistakes)**:';
        content += '\n| Wrong Spelling | Correct Spelling |';
        content += '\n| :------------:|:-------------:|';
        _scoreSP.scoreInfo.levelItems1.forEach(ssp=>{
          content += `\n| ${ssp.w} | ${ssp.r} |`;
        });
      }
      
      if(!_scoreSP.scoreInfo.levelItems2 || _scoreSP.scoreInfo.levelItems2.length<1){
		  content += '\n* **Difficult Mistakes (0 mistake)**:';
        content += 'NA';
      }
      else{
		  content += '\n* **Difficult Mistakes (' + _scoreSP.scoreInfo.levelItems2.length + ' mistakes)**:';
        content += '\n| Wrong Spelling | Correct Spelling |';
        content += '\n| :------------:|:-------------:|';
        _scoreSP.scoreInfo.levelItems2.forEach(ssp=>{
          content += `\n| ${ssp.w} | ${ssp.r} |`;
        });
      }
    }

    
    
    var _scoreGM = recEssay.scores.find(s=>s.c == 'GM');
	var gmMistakes = 0;
	if(_scoreGM && _scoreGM.scoreInfo) gmMistakes = _scoreGM.scoreInfo.errors.length;
	
    content += '\n#### 3.2) Grammar: Score '+ (_scoreGM ? _scoreGM.v : 0) + "/10" + ', ' + gmMistakes + ' mistakes\n';

	content += '* **Grammar Mistakes (' + gmMistakes + ' mistakes)**:';
    if(_scoreGM && _scoreGM.scoreInfo && _scoreGM.scoreInfo.errors && _scoreGM.scoreInfo.errors.length>0){
      content += '\n| Wrong Sentence | Correct Sentence| Type | Subtype |';
      content += '\n| :------------:|:-------------:|:-------------:|:-------------:|';
      _scoreGM.scoreInfo.errors.forEach(eGM=>{
        content += `\n| ${eGM.w} | ${eGM.r} | ${eGM.type} | ${eGM.subType} |`;
      });
    }
    else{
	  content += '* **Grammar Mistakes (0 mistake)**:';
      content += 'NA';
    }
    
    var _scoreVC = recEssay.scores.find(s=>s.c == 'VC');
	var vcMistakes = 0;
	
	if(_scoreVC && _scoreVC.scoreInfo) vcMistakes = _scoreVC.scoreInfo.errors.length;

    content += '\n#### 3.3) Vocabulary: Score '+ (_scoreVC ? _scoreVC.v : 0) + "/10" + ', ' + vcMistakes + ' mistakes\n';
	   
    if(_scoreVC && _scoreVC.scoreInfo){
      
      if(_scoreVC.scoreInfo.errors && _scoreVC.scoreInfo.errors.length>0){
		content += '* **Vocabulary Appropriateness: Score '+ (_scoreVC ? _scoreVC.v : 0) + '/10, (' + _scoreVC.scoreInfo.errors.length + ' mistakes)**:';
        content += '\n| Incorrect Usage Word | Incorrect Line | Suggested Correction | Explanation |';
        content += '\n| :------------:|:-------------:|:-------------:|:-------------:|';
        _scoreVC.scoreInfo.errors.forEach(eVC=>{
          content += `\n| ${eVC.w} | ${eVC.wl} | ${eVC.sg} | ${eVC.ex} |`;
        });
      }
      else{
		content += '* **Vocabulary Appropriateness: Score '+ (_scoreVC ? _scoreVC.v : 0) + '/10, 0 mistake**:';
        content += 'NA';
      }
      if(_scoreVC.scoreInfo.rareWords && _scoreVC.scoreInfo.rareWords.length>0){
		content += '\n* **Vocabulary Complexity: Score '+ (_scoreVC ? _scoreVC.v : 0) + '/10, ' + _scoreVC.scoreInfo.rareWords.length + ' words**:';
        content += '\n| Word | Type |';
        content += '\n|:----:|:----:|';
        _scoreVC.scoreInfo.rareWords.forEach(rw=>{
          content += `\n| ${rw.w} | ${rw.t == 'R'? 'Rare': rw.t=='U' ? 'Uncommon': '-'} |`;
        });
      }
    }

	var _scoreST = recEssay.scores.find(s=>s.c == 'ST');
    content += '\n#### 3.4) Structure & Flow: Score '+ (_scoreST ? _scoreST.v : 0) + "/10";
    content += '\n* **Score Categories**:';
    content += '\n| Score | Score Category |';
    content += '\n|:---:|:---:|';
    content += '\n| 1 | Needs Improvement |';
    content += '\n| 2 | Developing |';
    content += '\n| 3 | Competent |';
    content += '\n| 4 | Proficient |';
    
    if(_scoreST && _scoreST.sub && _scoreST.sub.length>0){
      _scoreST.sub.forEach(stSub=>{
        content += `${'\n\n`' + stSub.score + '/4`'} ${stSub.title}`;
        if(stSub.exampleImprovements && stSub.exampleImprovements.length>0){
          stSub.exampleImprovements.forEach(exi=>{
            content += `\n> * ${exi}`;
          });
        }
      });
    }
    content += '\n\n';
    content += '4. ### Overall Score: `' + recEssay.avgScoreP + '%`';
    const fileName = 'essay-report.txt';
    // console.log('------------- Report --------------');
    // console.log(content);

    // Create a Blob from the text
    const blob = new Blob([content], { type: 'text/plain' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;

    // Programmatically click the anchor to trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up by removing the anchor and revoking the object URL
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
	
  }
  const onDelete = async() =>{
    if(!window.confirm('Are you sure you want to delete this Essay?')) return;
    var _res = await AppHelper.apiPost('api/essay/remove', {token: oUser?oUser.token:'NA', essayId: recEssay.essayId});
    if(!_res || !_res.status){
      alert('ERROR: Failed to delete!');
      return;
    }
    else {
      //alert('Deleted!');
      if(essayFormDone){
        _res.code = 'DELETE';
        _res.essayId = recEssay.essayId;
        essayFormDone(_res);
      }
    }
  };
  const popSpellingErrorsClose = ()=>{
    setPopSpellingErrorsOpen(false);
  }
  const spellingErrorsView = (arg, param='')=>{
    if(arg.c == 'SP'){
      setScoreInfoSP(arg.scoreInfo);
      setPopSpellingErrorsOpen(true);
    }
    else if(arg.c == 'GM'){
      setScoreInfoGM(arg.scoreInfo);
      setPopGMErrorsOpen(true);
    }
    else if(arg.c == 'VC'){
      if(param == 'VCX'){
        setScoreInfoVCX(arg.scoreInfo);
        setPopVCXOpen(true);
      }
      else{
        setScoreInfoVC(arg.scoreInfo);
        setPopVCErrorsOpen(true);
      }
    }
  }
  const popGMErrorsClose = ()=>{
    setPopGMErrorsOpen(false);
  }
  const popVCErrorsClose = ()=>{
    setPopVCErrorsOpen(false);
  }
  const popVCXClose = ()=>{
    setPopVCXOpen(false);
  }
  
  const popShowLogClose = ()=>{
    setPopShowLogOpen(false);
  }
  const popShareClose = ()=>{
    setPopShareOpen(false);
  }
  const popScoreClose = ()=>{
    setPopScoreOpen(false);
  }
  
  const popShowFeedbackClose = ()=>{
    setPopShowFeedbackOpen(false);
  }
  
  const scoreExpand = (argScoreI)=>{
    var _rec = {...recEssay};
    _rec.scores[argScoreI].expand = !_rec.scores[argScoreI].expand;
    setRecEssay(_rec);
  }
  const suggestionPopClose = ()=>{
    setSuggestionPopOpen(false);
  }
  const suggestionView = (argIndex)=>{
    setSuggestionPopOpen(true);
	
	
    setSuggestPopItems([...recEssay.scores[argIndex].eg]);
  };
  const copyStudentEssay = ()=>{
    console.log(recEssay.text);
    navigator.clipboard.writeText(recEssay.text);
    alert('Copied to clipboard!');
  }
  const browseExercise = ()=>{
    window.open('/exercise', '_blank');
  }
  const onResubmit = async(arg)=>{
    console.log(arg);
    var _url = 'api/essay/create/withVersion/async';
    console.log(recEssay);
    var _payload = {token: oUser.token, imgs:[], 
      essayId: recEssay.essayId,
      text: recEssay.text, 
      topic: recEssay.topic, grade: recEssay.grade, addScore:0, profileId:profileId,
    };
    _payload.verScore = 'v' + recEssay.version;
    _payload.verCorr = 'v' + '2.0';//versionCorr;
    console.log('api call', _url, _payload);
    // setVMode('PROCESSING');
    // checkEssayEvalStatus('');
    var _res = await AppHelper.apiPost(_url, _payload);
    console.log('resubmitted', _res);
    if(!_res.status){
      alert('ERROR');
      return;
    }
    var _recEssay = {...recEssay};
    _recEssay.essayId = _res.data.essayId;
    setRecEssay(_recEssay);
    //fetchEssayDetail(_recEssay.essayId);
    setTimeout(() => {
      setStartCheckStatus(true);
    }, 1000*1);
    
    
    //if(!window.confirm('Are you sure you want to delete this Essay?')) return;
    // setIsResubmitting(true);
    // var _res = await AppHelper.apiPost('api/essay/resubmit', {token: oUser.token, essayId: recEssay.essayId, type: arg});
    // setIsResubmitting(false);
    // console.log(_res);
    // fetchEssayDetail(recEssay.essayId);
  }
  const popCmtView = (arg, argScore)=>{
    //console.log(arg, argScore);
    if(comments[argScore.c] && comments[argScore.c].list.length>0){
      setPopCmtText(comments[argScore.c].list[0].comment);
    }
    setPopCmtType(argScore.c);
    setPopCmtOpen(true);
  }
  const popCmtClose = (arg)=>{
    setPopCmtOpen(false);
  }
  const popCmtSave = ()=>{
    commentsLoad(recEssay.essayId);
  }
  const popErrorAddClose = ()=>{
    setPopErrorAddOpen(false);
  }
  const popInprogressClose = (arg)=>{
    setPopInprogressOpen(false);
  }
  const popResubmitClose = (arg)=>{
    setPopResubmitOpen(false);
  }
  const essayStatusCheckDone = (arg)=>{
    console.log('essayStatusCheckDone',arg);
    setStartCheckStatus(false);
    if(props.essayId)
      fetchEssayDetail(props.essayId);
  }
  const onResubmitPosted = (argEssayId)=>{
    console.log('onResubmitPosted',argEssayId);
    essayFormDone({code:'NEW', essayId: argEssayId});
  }
  const onMarkDoneGM = async(argErrorCount)=>{
    var _url = 'api/essay/score/update/GM'; 
	  var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:argErrorCount};
	  var _res = await AppHelper.apiPost(_url, _payload);
    if(!_res.status){
      return;
    }
    fetchEssayDetail_sync(recEssay.essayId);
  };
  const onMarkDoneVCA = async(argErrorCount)=>{
    var _url = 'api/essay/score/update/VCA'; 
	  var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:argErrorCount};
	  var _res = await AppHelper.apiPost(_url, _payload);
    if(!_res.status){
      return;
    }
    fetchEssayDetail_sync(recEssay.essayId);
  };
  const onMarkDoneVCX = async(argVCXPoint)=>{
    var _url = 'api/essay/score/update/VCX'; 
	  var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, vcxPoint:argVCXPoint};
	  var _res = await AppHelper.apiPost(_url, _payload);
    console.log(_res);
    if(!_res.status){
      return;
    }
    fetchEssayDetail_sync(recEssay.essayId);
  };
  const onMarkDoneSP = async(argErrorCount)=>{
    var _url = 'api/essay/score/update/SP';
	  var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:argErrorCount};
	  var _res = await AppHelper.apiPost(_url, _payload);
    if(!_res.status){
      return;
    }
    fetchEssayDetail_sync(recEssay.essayId);
  };
  const onWordSelect = ()=>{
    if(isShared) return;
    if(!oUser) return;
	  var isTEA = oUser.accountType=="TEA";
	  if(!isTEA) return;
	  
    console.log('onWordSelect');
    const selection = window.getSelection();
    if (!selection || selection.rangeCount < 1){
      alert('selection.rangeCount', selection.rangeCount);
      return;
    }
    const range = selection.getRangeAt(0);
    const startNode = range.startContainer;
    const startOffset = range.startOffset;
    const originalText = startNode.textContent;
    const selectedText = selection.toString().trim();
    if (selectedText == ''){
      console.log('selectedText EMPTY');
      return;
    }
    const startIndex = originalText.indexOf(selectedText);
    const parentSpan = selection.anchorNode.parentElement;
    const spanIndex = Array.from(parentSpan.parentNode.children).indexOf(parentSpan);
    if(parentSpan.parentNode.children[spanIndex].style.textDecoration == 'underline'){
      alert('It should not select the corrected word(s)!');
      return;
    }
    
    const spanCount = parentSpan.parentNode.children.length;
    var _essayText = '';
    var _wordIndex = 0;
    for(var i=0;i<spanCount;i++){
      if(parentSpan.parentNode.children[i].style.textDecoration != 'underline'){
        _essayText += parentSpan.parentNode.children[i].textContent;
        //console.log(parentSpan.parentNode.children[i].style.fontWeight, parentSpan.parentNode.children[i].textContent);
        if(i<spanIndex)
          _wordIndex += parentSpan.parentNode.children[i].textContent.length;
        else if(i==spanIndex)
          _wordIndex += startIndex;
      }
    }
    _wordIndex += 1;

    const sentences = _essayText.split(/[.!]/);
    let _selectedLine = '';
    for (const sentence of sentences) {
      if (_wordIndex >= _essayText.indexOf(sentence) && _wordIndex <= _essayText.indexOf(sentence) + sentence.length) {
        _selectedLine = sentence.trim();
        break;
      }
    }

    setPopErrorAddOpen(true);
    setPopErrorAddWords(selectedText);
    setPopErrorAddStartIndex(_wordIndex);
    setPopErrorAddLine(_selectedLine);
    console.log('word index', _wordIndex);
    console.log('word line', _selectedLine);
  }
  const onErrorAddDone = async(arg)=>{
    if(arg == 'SP'){
      var _errCount = 1;
      _errCount += scoreInfoSP.levelItems0 ? scoreInfoSP.levelItems0.filter(x=>x.isFalse!=1).length : 0;
      _errCount += scoreInfoSP.levelItems1 ? scoreInfoSP.levelItems1.filter(x=>x.isFalse!=1).length : 0;
      _errCount += scoreInfoSP.levelItems2 ? scoreInfoSP.levelItems2.filter(x=>x.isFalse!=1).length : 0;

      var _url = 'api/essay/score/update/SP'; 
      var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:_errCount};
      var _res = await AppHelper.apiPost(_url, _payload);
      if(!_res.status){
        return;
      }
      fetchEssayDetail_sync(recEssay.essayId);
    }
    else if(arg == 'GM'){
      var _errCount = (scoreInfoGM.errors ? scoreInfoGM.errors.length : 0) + 1;
      var _url = 'api/essay/score/update/GM'; 
      var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:_errCount};
      var _res = await AppHelper.apiPost(_url, _payload);
      if(!_res.status){
        return;
      }
      fetchEssayDetail_sync(recEssay.essayId);
    }
    else if(arg == 'VCA'){
      var _errCount = (scoreInfoVC.errors ? scoreInfoVC.errors.length : 0) + 1;
      var _url = 'api/essay/score/update/VCA'; 
      var _payload = {token: oUser.token, essayId: recEssay.essayId, verScore:recVersion, essayText:recEssay.text, errorCount:_errCount};
      var _res = await AppHelper.apiPost(_url, _payload);
      if(!_res.status){
        return;
      }
      fetchEssayDetail_sync(recEssay.essayId);
    }
    else if(arg == 'VCX'){
      fetchEssayDetail_sync(recEssay.essayId);
    }
  }
  const onPdfDownload = async()=>{
    setPdfDownloading(true);
    var _url = 'api/essay/share'; 
	  var _payload = {token: oUser.token, essayId: recEssay.essayId};
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status || !_res.data || !_res.data.shareId) {
      setPdfDownloading(false);
		  alert('ERROR: Failed to add new share!');
		  return;
	  }
    var _shareUrl = AppHelper.shareUrl(_res.data.shareId);
    _shareUrl = _shareUrl.replace('/share', '/share/print');
    
    _url = AppHelper.apiUrlGet('api/essay/pdf'); 
	  _payload = {url:_shareUrl};
    //_res = await AppHelper.apiPost(_url, _payload);
    try {
      var _resPdf = await AppHelper.apiPost('api/essay/pdf', _payload);
      console.log(_resPdf);
      if (!_resPdf || !_resPdf.status || !_resPdf.data) {
        setPdfDownloading(false);
        alert('ERROR: Failed to download the PDF!');
        return;
      }
      const linkSource = `data:application/pdf;base64,${_resPdf.data}`;
      const downloadLink = document.createElement('a');
      const link = document.createTextNode('Click here to download');

      // Set download attributes
      downloadLink.href = linkSource;
      downloadLink.download = `${recEssay.student}_${recEssay.grade}_${recEssay.topic}_${dayjs().format('YYYY-MM-DD')}.pdf`;

      // Append the link to the body (needed for some browsers)
      downloadLink.appendChild(link);
      document.body.appendChild(downloadLink);

      // Programmatically click the link to trigger the download
      downloadLink.click();

      // Remove the link after the download is triggered
      document.body.removeChild(downloadLink);

      // const response = await fetch(_url, {
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify(_payload),
      // });

      // if (!response.ok) {
      //     throw new Error('Network response was not ok');
      // }

      // const blob = await response.blob();
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = `${recEssay.student}_${recEssay.grade}_${recEssay.topic}_${dayjs().format('YYYY-MM-DD')}.pdf`;
      // document.body.appendChild(a);
      // a.click();
      // a.remove();
      // window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
    setPdfDownloading(false);
  }
  return (
    <>
    { !isLoading && !startCheckStatus && recEssay.topic &&
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={6}>
          { isShared &&
          <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0.5rem'}}>
            {recEssay.student}
          </Typography>
          }
        </Grid>
        <Grid item xs={6}>
          <Box mb={1}  display={'flex'} justifyContent={'right'}>
            <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0rem'}}>
              {recGrade}
            </Typography>
            {/* <FormControl error={!gradeOK}>
              <InputLabel id='lblGrade'></InputLabel>
              <Select labelId='lblGrade'
                value={grade}
                onChange={gradeSelect}
                label=""
                sx={{ "& fieldset": { border: 'none' } }}
                disabled
              >
                {gradeList.map((item) => (
                  <MenuItem key={item.v} value={item.v}>
                    {item.n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Box>
        </Grid>
      </Grid>
      <Box 
        sx={{
          borderBottomLeftRadius:'20px', borderBottomRightRadius:'20px',
          //maxHeight: printMode?'none':'30rem', 
          overflowY:'auto', 
          '&::-webkit-scrollbar': {
            width: '6px',      // Width of the scrollbar
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Track color
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',     // Thumb color
            borderRadius: '4px',   // Border radius
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',     // Thumb color on hover
          },
        }}>
      <Grid container>
        {/* <Grid item xs={12} sm={6}>
          <Box display={'flex'} sx={{fontSize:'1.2rem', fontWeight:'bold', justifyContent:'space-between'}} ml={1}>
            <div>Student's copy
              <ContentCopyIcon onClick={copyStudentEssay} sx={{marginLeft:'0.5rem', cursor:'pointer'}} />
            </div>
            
		      </Box>
          <Card variant="outlined" sx={{padding:1.5, marginRight:1, borderRadius:'20px'}}>
            <Grid item xs={12} mb={1}>
              <Box sx={{fontWeight:'bold', fontFamily: "'Playpen Sans', cursive", fontSize:'2rem', textAlign:'center'}}>{recEssay.topic}</Box>
            </Grid>
            <Grid item xs={12}>
              <Box 
                sx={{
                  fontSize:'1.3rem', lineHeight:'35px', fontFamily: "'Playpen Sans', cursive", 
                  background:'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize:'100% 35px',
                  border:'0 solid #efefef', borderRadius:'6px', padding:'5px', marginLeft:'5px', 
                }}>
                {convertNewlinesToBr(recEssay.text)}
              </Box>
            </Grid>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <Box display={'flex'} sx={{fontSize:'1.2rem', fontWeight:'bold', justifyContent:'space-between', overflowX:'hidden'}} ml={0}>
            {/* <div>
              Corrected copy
              <ContentCopyIcon onClick={copyStudentEssay} sx={{marginLeft:'0.5rem', cursor:'pointer'}} />
            </div> */}
            <div style={{flexGrow:1}}>
              <div style={{display:'flex'}}>
                { !isShared && configJson.allowPdf==1 && !printMode && !pdfDownloading &&
                <>
                <Button onClick={onPdfDownload} variant='text' style={{color:'rgba(19,130,117,1)',textTransform:'none', marginLeft:'-6px'}} endIcon={<PictureAsPdfIcon/>}>
                  Download PDF
                </Button>
                {/* <a onClick={onPdfDownload} className='a-btn-pdf' style={{fontSize:'1rem'}}>
                Download PDF
                </a> */}
                {/* <PictureAsPdfIcon onClick={onPdfDownload} className='app-green' sx={{marginLeft:'0.5rem', cursor:'pointer'}} /> */}
                </>
                }
                { pdfDownloading &&
                <div style={{fontSize:'0.8rem', fontWeight:'normal'}}>Downloading..</div>
                }
              </div>
              {/* <Button onClick={onPdfDownload} color="success" variant="text" style={{marginRight:'2px'}}></Button> */}
            {/* {((!printMode && recEssay.imgs && recEssay.imgs != undefined && recEssay.imgs.length > 0)) && (
              <>
              <Button sx={{marginBottom:"10px", marginRight:"10px"}} className="app-btn-bg"
                onClick={onScan} color="info" variant="contained">Original Scan</Button>
              <ScanView isOpen={isPopupOpen} pages={recEssay.imgs} numPages={recEssay.imgs.length} onClose={handleClosePopup} />
              { isSa && (
              <>
                <Button sx={{marginBottom:"10px", marginRight:"10px", backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={onScan2} color="info" variant="contained">{'VIEW'}</Button>
                <ScannerView isOpen={isPopupOpen2}  onClose={handleClosePopup2} />
              </>
              )}
              </>
            )} */}
            </div>
            {((!printMode && recEssay.imgs && recEssay.imgs != undefined && recEssay.imgs.length > 0)) && (
              <>
              <Button onClick={onScan} variant='text' style={{color:'#000', textTransform:'none'}} endIcon={<WallpaperIcon/>}>Original Scan</Button>
              {/* <div style={{display:'flex', marginBottom:'3px', fontSize:'1rem'}}>
                <div style={{lineHeight:1}}>Original Scan</div>
                <WallpaperIcon onClick={onScan} sx={{marginLeft:'0.5rem', cursor:'pointer'}} />
              </div> */}
              {/* <Button sx={{marginBottom:"10px", marginRight:"10px"}} className="app-btn-bg"
                onClick={onScan} color="info" variant="contained">Original Scan</Button> */}
              <ScanView isOpen={isPopupOpen} pages={recEssay.imgs} numPages={recEssay.imgs.length} onClose={handleClosePopup} />
              </>
            )}
            { !printMode &&
            <Button onClick={copyStudentEssay} variant='text' style={{color:'#000',marginLeft:'0.5rem', textTransform:'none', paddingRight:'2px'}}
              endIcon={<ContentCopyIcon/>}>
              Copy Original
            </Button>
            // <div style={{display:'flex', marginBottom:'3px', marginLeft:'1rem', fontSize:'1rem'}}>
            //   <div style={{lineHeight:1}}>Copy Original</div>
            //   <ContentCopyIcon onClick={copyStudentEssay} sx={{marginLeft:'0.5rem', cursor:'pointer'}} />
            // </div>
            }
          </Box>
          <Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px', marginTop:'0.25rem'}}>
            <Grid item xs={12} mb={1}>
              <Box sx={{fontWeight:'bold', fontFamily: "'Playpen Sans', cursive", fontSize:'2rem', textAlign:'center'}}>{recEssay.topic}</Box>
            </Grid>
            <Grid item xs={12}>
               <Box id='essayViewBox' style={{ cursor: 'context-menu' }}
                onSelect={onTextSelectionChange}
                sx={{
                  fontSize:'1.3rem', lineHeight:'35px', fontFamily: "'Playpen Sans', cursive",
                  background:'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize:'100% 35px',
                  border:'0 solid #efefef', borderRadius:'6px', padding:'5px', marginLeft:'5px', 
                }}>
                  {getDifferences()}
                {/* <Menu
                  open={contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                  contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                  }
                >
                  {totalSelectedWords==1 && <MenuItem onClick={handleClose}>Spelling Mistake</MenuItem>}
                  {(totalSelectedWords>=1) && <MenuItem onClick={handleClose}>Grammar Mistake</MenuItem>}
                  {totalSelectedWords==1 && <MenuItem onClick={handleClose}>Vocabulary Mistake</MenuItem>}
                </Menu> */}

              </Box>
            </Grid>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Box mb={.5} pt={0}>
            {recEssay.topic}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={.5} pt={0} backgroundColor={'#fff'}>
            {recEssay.topic}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ fontSize:'1.3rem', lineHeight:'1.6', fontFamily: "'Playpen Sans', cursive", height:'30rem', backgroundColor:'#fff', border:'1px solid #efefef', borderRadius:'6px', padding:'5px', overflowY:'scroll'}}>
            {convertNewlinesToBr(recEssay.text)}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box 
            sx={{
              fontSize:'1.3rem', lineHeight:'35px', fontFamily: "'Playpen Sans', cursive", height:'30rem', 
              background:'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize:'100% 35px',
              border:'1px solid #efefef', borderRadius:'6px', padding:'5px', marginLeft:'5px', overflowY:'scroll',
            }}>
            {getDifferences()}
          </Box>
        </Grid> */}
      </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box id='boxWordCount' sx={{mb:1}}>
            {wordsCount} words
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{mb:1}}>
            {/* {wordsCount2} words */}
          </Box>
        </Grid>
      </Grid>
      <Grid container pt={.5}>
        <Grid item xs={12} md={recVersion==1?6:12}>
          <Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px'}}>
            { recEssay &&
              ((recVersion == 1) || (recVersion == 2 || recVersion == 2.1 || recVersion == 2.2)) &&
            <>
            <Grid container>
              <Grid item xs={6} sm={8} mb={1}>
                <Box id='boxScoreTitle' sx={{fontWeight:'bold', fontSize:'2rem'}}>SCORE</Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                  { !printMode &&
                  <Box display={'flex'} justifyContent={'right'}>
                  <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0.5rem'}}>
                    v{recVersion == 4 ? '4.0' :recVersion == 3.1 ? '3.1' :recVersion == 3 ? '3.0' : recVersion == 2.2 ? '2.2' : recVersion == 2.1 ? '2.1' : recVersion == 2 ? '2.0' : '1.0'}
                  </Typography>
                  </Box>
                  }
                  { printMode &&
                  <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
                  <div style={{color:"rgba(19,130,117,1)", fontWeight:'bold', textAlign:'center'}}>Scan here for detailed feedback</div>
                  </div>
                  }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={(printMode && qrValue)?8:12}>
                { recEssay && recVersion == 1 &&
                  <>  
                  { recEssay && recEssay.scores && recEssay.scores.map((sc, iSc)=>(
                    <Grid container mb={0.6} key={'score_' + iSc}>
                      <Grid item xs={10} md={10}>
                        <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', display:'flex'}} flexGrow={1}>
                          <Box sx={{ borderRadius:'50px', backgroundColor:'rgb(99, 136, 137)', width:`${sc.p}%`, height:'100%', padding:'3px 0 3px 15px', alignItems:'center', display:'flex', fontWeight:'bold' }}>
                            {sc.v}/5 {sc.n}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} md={2} textAlign={'center'}>
                        { sc.eg && sc.eg.length > 0 &&
                          // <Chip  label={sc.eg.length} color="secondary" sx={{marginLeft:'2px', marginBottom:'2px', backgroundColor:'rgb(157, 188, 152)'}} />
                          <Chip onClick={()=>suggestionView(iSc)}
                            icon={<TipsAndUpdatesIcon />} label={sc.eg.length} 
                            sx={{
                              backgroundColor:'rgb(99, 136, 137)',
                              color: '#F9EFDB',
                              fontWeight: 'bold',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }} 
                          />
                        }
                      </Grid>
                    </Grid>
                    // <React.Fragment>
                    //   <Box display="flex" flexDirection="row" sx={{marginBottom:'4px'}}>
                    
                    //     <Box>
                    //       { sc.eg && 
                    //         
                    //       }
                    //     </Box>
                    //   </Box>
                    // </React.Fragment>
                  ))}
                  <Grid container mt={3}>
                    <Grid item xs={10} md={10}>
                      <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', marginBottom:'4px'}} flexGrow={1}>
                        <Box sx={{ borderRadius:'50px', backgroundColor:'#1976d2', padding:'3px 0 3px 15px', width:`${recEssay.avgScoreP}%`, height:'100%', alignItems:'center', display:'flex', fontWeight:'bold'  }}>
                          {/* Overall {Math.floor(recEssay.avgScoreP)}% */}
                          Overall {Math.round(recEssay.avgScoreP)}%
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>

                    </Grid>
                  </Grid>
                  {/* <Box display="flex" flexDirection="row" sx={{marginTop:'25px'}}>
                    
                    <Box>
                      <Chip label={'#'} color="secondary" sx={{marginLeft:'2px', marginBottom:'2px'}} />
                    </Box>
                  </Box> */}
                  </>
                }
                { recEssay && (recVersion == 2 || recVersion == 2.1 || recVersion == 2.2) &&
                  <>  
                  { recEssay && recEssay.scores && recEssay.scores.map((sc, iSc)=>(
                    <Grid container mb={0.6} key={'score_' + iSc}>
                      <Grid item xs={10} md={10}>
                        <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', display:'flex'}} flexGrow={1}>
                          <Box sx={{ borderRadius:'50px', backgroundColor:'rgb(99, 136, 137)', width:`${sc.p}%`, height:'100%', padding:'3px 0 3px 15px', alignItems:'center', display:'flex', fontWeight:'bold' }}>
                            {sc.v}/4 {sc.n}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} md={2} textAlign={'center'}>
                        {/* { sc.eg &&
                          <div>{sc.eg.length}</div>
                        } */}
                        { sc.eg && sc.eg.length > 0 &&
                          <Chip onClick={()=>scoreExpand(iSc)}
                            icon={sc.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={sc.eg.length} 
                            sx={{
                              backgroundColor:'rgb(99, 136, 137)',
                              color: '#F9EFDB',
                              fontWeight: 'bold',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }} 
                          />
                        }
                      </Grid>
                      { sc.expand && sc.sub && sc.sub.map((rSub, iSub)=>(
                        <Grid container>
                          <Grid item xs={12} mt={1}>
                            <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                            {rSub.score}/4 {rSub.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {rSub.exampleImprovements && rSub.exampleImprovements.map((sEg,iSEg)=>(
                              <Grid container>
                                <Grid item xs={10}>
                                  <ListItem key={'sug_iSub_' + iSEg}>
                                    <ListItemIcon sx={{minWidth:'40px'}}>
                                    <TipsAndUpdatesIcon sx={{color:'rgb(157, 188, 152)'}} />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={sEg}
                                    />
                                  </ListItem>
                                  {/* <Box>
                                    <TipsAndUpdatesIcon sx={{color:'rgb(157, 188, 152)'}} />
                                    {sEg}
                                  </Box> */}
                                </Grid>
                                <Grid item xs={2}></Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      ))
                      }
                    </Grid>
                  ))}
                  <Grid container mt={3}>
                    <Grid item xs={10} md={10}>
                      <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', marginBottom:'4px'}} flexGrow={1}>
                        <Box sx={{ borderRadius:'50px', backgroundColor:'#1976d2', padding:'3px 0 3px 15px', width:`${recEssay.avgScoreP}%`, height:'100%', alignItems:'center', display:'flex', fontWeight:'bold'  }}>
                          Overall {Math.floor(recEssay.avgScoreP)}%
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                    </Grid>
                  </Grid>
                  </>
                }
                { recEssay && (recVersion == 3 || recVersion == 3.1 || recVersion == 4.0) &&
                  <>  
                  { recEssay && recEssay.scores && recEssay.scores.map((sc, iSc)=>(
                    <Grid container mb={0.6} key={'score_' + iSc}>
                      { 1==2 &&
                      <>
                      <Grid item xs={5} md={10}>
                        <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', display:'flex'}} flexGrow={1}>
                          <Box className='app-bg-g' sx={{ borderRadius:'50px', 
                            //backgroundColor:'rgb(99, 136, 137)', 
                            width:`${sc.p}%`, height:'100%', padding:'3px 0 3px 15px', alignItems:'center', display:'flex', fontWeight:'bold' 
                          }}>
                            {sc.v}/{recEssay.version>=3?'10':'4'} {sc.n}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={7} md={2} textAlign={'center'}>
                        {/* { !isResubmitting &&
                        <>
                          { sc.c == 'GM' && !isOK_GM && (
                          <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={()=>onResubmit('GM')} color="info" variant="contained">Resubmit</Button>
                          )}
                          { sc.c == 'SP' && !isOK_SP && (
                            <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={()=>onResubmit('SP')} color="info" variant="contained">Resubmit</Button>
                          )}
                          { sc.c == 'VC' && !isOK_VC && (
                            <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={()=>onResubmit('VC')} color="info" variant="contained">Resubmit</Button>
                          )}
                          { sc.c == 'ST' && !isOK_ST && (
                            <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={()=>onResubmit('ST')} color="info" variant="contained">Resubmit</Button>
                          )}
                        </>
                        } */}
                        { isResubmitting && 
                          <span>Resubmitting..</span>
                        }
                        
                        {/* { sc.eg &&
                          <div>{sc.eg.length}</div>
                        } */}
                        { sc.eg && sc.eg.length > 0 && (
                          <>
                          <Chip onClick={()=>scoreExpand(iSc)}
                            icon={sc.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={sc.eg.length} 
                            className='app-bg-g'
                            sx={{
                              //backgroundColor:'rgb(99, 136, 137)',
                              color: '#fff',
                              fontWeight: 'bold',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              },
                              width:'65px'
                            }} 
                          />
                          </>
                        )}
                        { iamTEA && !isSample && !isShared && isMyEssay &&
                          (sc.c!='GM' || isOK_GM) && (sc.c!='SP' || isOK_SP) && 
                          (sc.c!='VC' || isOK_VC) && (sc.c!='ST' || isOK_ST) && 
                          <Chip onClick={()=>popCmtView(iSc, sc)}
                            icon={<img src='../../img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                            label={'Comment'}
                            className='app-bg-g'
                            sx={{
                              //backgroundColor:'rgb(99, 136, 137)',
                              color: '#fff',
                              fontWeight: 'bold', marginLeft:'2px',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }} 
                          />
                        }
                      </Grid>
                      </>
                      }
                      <Grid item xs={12}>
                        <Box display='flex' width='100%'>
                          <Box flexGrow={1} paddingRight={'.2rem'}>
                            <Box flexGrow={1} 
                              sx={{
                                border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', display:'flex',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}>
                              <Box className='app-bg-g' sx={{ borderRadius:'50px', 
                                //backgroundColor:'rgb(99, 136, 137)', 
                                width:`${sc.p}%`, height:'100%', padding:'3px 0 3px 15px', alignItems:'center', display:'flex', fontWeight:'bold' 
                              }}>
                                {sc.v}/{recEssay.version>=3?'10':'4'} {sc.n}
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            { isResubmitting && 
                              <span>Resubmitting..</span>
                            }
                            
                            {/* { sc.eg &&
                              <div>{sc.eg.length}</div>
                            } */}
                            { sc.eg && sc.eg.length > 0 && (
                              <>
                              <Chip onClick={()=>scoreExpand(iSc)}
                                icon={sc.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={sc.eg.length} 
                                className='app-bg-g'
                                sx={{
                                  //backgroundColor:'rgb(99, 136, 137)',
                                  color: '#fff',
                                  fontWeight: 'bold',
                                  [`& .${chipClasses.icon}`]: {
                                    color: '#fff'
                                  },
                                  width:'65px'
                                }} 
                              />
                              </>
                            )}
                            { iamTEA && !isSample && !isShared && isMyEssay &&
                              (sc.c!='GM' || isOK_GM) && (sc.c!='SP' || isOK_SP) && 
                              (sc.c!='VC' || isOK_VC) && (sc.c!='ST' || isOK_ST) && 
                              <Chip onClick={()=>popCmtView(iSc, sc)}
                                icon={<img src='../../img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                                label={'Comment'}
                                className='app-bg-g'
                                sx={{
                                  //backgroundColor:'rgb(99, 136, 137)',
                                  color: '#fff',
                                  fontWeight: 'bold', marginLeft:'2px',
                                  [`& .${chipClasses.icon}`]: {
                                    color: '#fff'
                                  }
                                }} 
                              />
                            }
                          </Box>
                        </Box>
                      </Grid>

                      { sc.expand && sc.sub && 
                        <ScoreSubExpand records={sc.sub} essay={recEssay} allowMark={iamTEA && !isShared && isMyEssay} />
                      }
                      { sc.expand && sc.scoreInfo &&
                      <Grid container>
                        <Grid item xs={12}>
                          <Box>
                            { sc.scoreInfo.blocks && sc.scoreInfo.blocks.length>0 &&(
                            <>
                              <Box display={'flex'} alignItems={'center'}>
                                <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {sc.scoreInfo.blocks[0].title}
                                </Typography>
                                { !sc.scoreInfo.blocks[0].hide && (
                                  <Fab onClick={()=> spellingErrorsView(sc)} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                    <OpenInNewIcon />
                                  </Fab>
                                )}
                              </Box>
                              { sc.scoreInfo.blocks[0].subTitles && sc.scoreInfo.blocks[0].subTitles.map((rSubT, iSubT)=>(
                                <Box>
                                  <Grid item xs={12} mt={0}>
                                    <Box display={'flex'} alignItems={'center'}>
                                      <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                        {rSubT}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Box>
                              ))}
                            </>  
                            )}
                            { (!sc.scoreInfo.blocks || sc.scoreInfo.blocks.length<1) &&(
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {sc.scoreInfo.title}
                              </Typography>
                              <Fab onClick={()=> spellingErrorsView(sc)} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)', minWidth:'40px'}}>
                                <OpenInNewIcon />
                              </Fab>
                            </Box>
                            )}
                          {/* <Chip onClick={()=> spellingErrorsView(sc)}
                            icon={<OpenInNewIcon />}
                            sx={{
                              backgroundColor:'rgb(99, 136, 137)',
                              color: '#F9EFDB',
                              fontWeight: 'bold', marginLeft: '5px',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }} 
                            /> */}
                          </Box>
                        </Grid>
                        { sc.scoreInfo.blocks && sc.scoreInfo.blocks.length>1 &&(
                        <Grid item xs={12} mt={0.5}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                              {sc.scoreInfo.blocks[1].title}
                            </Typography>
                            <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                              <OpenInNewIcon />
                            </Fab>
                          </Box>
                          { sc.scoreInfo.blocks[1].subTitles && sc.scoreInfo.blocks[1].subTitles.map((rSubT, iSubT)=>(
                            <Grid item xs={12} mt={0}>
                              <Box display={'flex'} alignItems={'center'}>
                                <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                  {rSubT}
                                </Typography>
                                {/* { iSubT==0 && sc.scoreInfo.rareWords && sc.scoreInfo.rareWords.length>0 && (
                                  <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                    <OpenInNewIcon />
                                  </Fab>
                                )} */}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        )}
                        { sc.scoreInfo.subTitles && sc.scoreInfo.subTitles.map((rSubT, iSubT)=>(
                          <Grid item xs={12} mt={0}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {rSubT}
                              </Typography>
                              {/* { iSubT==0 && sc.scoreInfo.rareWords && sc.scoreInfo.rareWords.length>0 && (
                                <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                  <OpenInNewIcon />
                                </Fab>
                              )} */}
                            </Box>
                          </Grid>
                        ))}
                        {/* <Box sx={{maxWidth:'450px', width:'100%', paddingTop:'0.5rem'}}>
                          <Grid item xs={12} mt={0.3} sx={{border:'1px solid #333'}}>
                            <Grid container>
                              <Grid item xs={6} sx={{borderRight:'1px solid #333', textAlign:'center'}}>
                                <Typography sx={{marginLeft:'1rem'}}>WORD</Typography>
                              </Grid>
                              <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Typography sx={{marginLeft:'1rem'}}>LEVEL</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          { sc.scoreInfo.levelItems && sc.scoreInfo.levelItems.map((lt, iLt)=>(
                            <Grid item xs={12} mt={0.3} sx={{borderBottom:'1px solid #333', borderLeft:'1px solid #333', borderRight:'1px solid #333'}}>
                              <Grid container>
                                <Grid item xs={6} sx={{borderRight:'1px solid #333', textAlign:'center'}}>
                                  <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                    {lt.w}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'center'}}>
                                  <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                    {lt.l}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box> */}
                      </Grid>
                      }
                      <Grid item xs={10}>
                        { comments[sc.c] && comments[sc.c].list.length>0 && 
                          comments[sc.c].list.map(cmm=>(
                            <>
                            <div style={{fontSize:'0.8rem', display:'flex',}}>
                              <div>
                                <img src='/img/teacher-dark.png' style={{width:'25px', marginRight:'3px', marginBottom:'0'}} />
                              </div>
                              <div className='line-cmt' style={{marginTop:'3px'}}>
                                <span style={{color:'rgba(0,0,0,0.8)', letterSpacing:1}}>Tutor's comment:</span>{' ' + cmm.comment}
                              </div>
                            </div>
                            </>
                          ))
                        }
                      </Grid>
                    </Grid>
                  ))}
                  <Grid container mt={3}>
                    { 1==2 &&
                      <>
                      <Grid item xs={7} md={10}>
                        <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', marginBottom:'4px'}} flexGrow={1}>
                          <Box ref={boxOverall} id="boxOverall" sx={{ borderRadius:'50px', backgroundColor:'#1976d2', padding:'3px 0 3px 15px', width:`${recEssay.avgScoreP}%`, height:'100%', alignItems:'center', display:'flex', fontWeight:'bold'  }}>
                            {/* Overall {Math.floor(recEssay.avgScoreP)}% */}
                            Overall {Math.round(recEssay.avgScoreP)}%
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={5} md={2} textAlign={'center'}>
                        {/* { oUser && oUser.token != 'NA' &&
                          <Chip onClick={()=>onScoreView()}
                            //icon={<img src='./img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                            label={'Score'}
                            className='app-bg-g'
                            sx={{
                              //backgroundColor:'rgb(99, 136, 137)',
                              color: '#fff',
                              fontWeight: 'bold', marginLeft:'2px',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }}
                          />
                        } */}
                        { iamTEA && !isSample && !isShared && isMyEssay &&
                          <Chip onClick={()=>popCmtView(-1, {c:'OA'})}
                            icon={<img src='../../img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                            label={'Comment'}
                            className='app-bg-g'
                            sx={{
                              //backgroundColor:'rgb(99, 136, 137)',
                              color: '#fff',
                              fontWeight: 'bold', marginLeft:'2px',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }}
                          />
                        }
                      </Grid>
                      </>
                    }
                    <Grid item xs={12}>
                      <Box display='flex' width='100%'>
                        <Box flexGrow={1} paddingRight={'.2rem'}>
                          <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', marginBottom:'4px'}} flexGrow={1}>
                            <Box ref={boxOverall} id="boxOverall" sx={{ borderRadius:'50px', backgroundColor:'#1976d2', padding:'3px 0 3px 15px', width:`${recEssay.avgScoreP}%`, height:'100%', alignItems:'center', display:'flex', fontWeight:'bold'  }}>
                              {/* Overall {Math.floor(recEssay.avgScoreP)}% */}
                              Overall {Math.round(recEssay.avgScoreP)}%
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <div style={{width:'65px'}}></div>
                          { iamTEA && !isSample && !isShared && isMyEssay &&
                            <Chip onClick={()=>popCmtView(-1, {c:'OA'})}
                              icon={<img src='../../img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                              label={'Comment'}
                              className='app-bg-g'
                              sx={{
                                //backgroundColor:'rgb(99, 136, 137)',
                                color: '#fff',
                                fontWeight: 'bold', marginLeft:'2px',
                                [`& .${chipClasses.icon}`]: {
                                  color: '#fff'
                                }
                              }}
                            />
                          }
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      { comments.OA && comments.OA.list.length>0 && 
                        comments.OA.list.map(cmm=>(
                          <>
                          <div style={{fontSize:'0.8rem', display:'flex',}}>
                            <div>
                              <img src='../../img/teacher.png' style={{width:'25px', marginRight:'3px', marginBottom:'0'}} />
                            </div>
                            <div className='line-cmt' style={{marginTop:'3px'}}>
                              <span style={{color:'rgba(0,0,0,0.8)', letterSpacing:1}}>Tutor's comment:</span>{' ' + cmm.comment}
                            </div>
                          </div>
                          </>
                        ))
                      }
                    </Grid>
                  </Grid>
                  </>
                }
              </Grid>
              { printMode && qrValue &&
              <Grid item xs={12} sm={4}>
              </Grid>
              }
            </Grid>
            </>
            }
            { recEssay && recVersion>=3 &&
            <>
              {/* <div style={{pageBreakBefore:'always'}}></div> */}
              <div id="boxPageBreak"></div>
              <Box display='flex' width='100%' style={{marginBottom:'1rem'}}>
                <Box flexGrow={1}>
                  <Box id='boxScoreTitle' sx={{fontWeight:'bold', fontSize:'2rem'}}>SCORE</Box>
                  <Box> 
                  { recEssay && recEssay.scores && recEssay.scores.map((sc, iSc)=>(
                    <Grid container mb={0.6} key={'score_' + iSc}>
                      { 1==2 &&
                      <>
                      <Grid item xs={5} md={10}>
                        <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', display:'flex'}} flexGrow={1}>
                          <Box className='app-bg-g' sx={{ borderRadius:'50px', 
                            //backgroundColor:'rgb(99, 136, 137)', 
                            width:`${sc.p}%`, height:'100%', padding:'3px 0 3px 15px', alignItems:'center', display:'flex', fontWeight:'bold' 
                          }}>
                            {sc.v}/{recEssay.version>=3?'10':'4'} {sc.n}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={7} md={2} textAlign={'center'}>
                        {/* { !isResubmitting &&
                        <>
                          { sc.c == 'GM' && !isOK_GM && (
                          <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={()=>onResubmit('GM')} color="info" variant="contained">Resubmit</Button>
                          )}
                          { sc.c == 'SP' && !isOK_SP && (
                            <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={()=>onResubmit('SP')} color="info" variant="contained">Resubmit</Button>
                          )}
                          { sc.c == 'VC' && !isOK_VC && (
                            <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={()=>onResubmit('VC')} color="info" variant="contained">Resubmit</Button>
                          )}
                          { sc.c == 'ST' && !isOK_ST && (
                            <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={()=>onResubmit('ST')} color="info" variant="contained">Resubmit</Button>
                          )}
                        </>
                        } */}
                        { isResubmitting && 
                          <span>Resubmitting..</span>
                        }
                        
                        {/* { sc.eg &&
                          <div>{sc.eg.length}</div>
                        } */}
                        { sc.eg && sc.eg.length > 0 && (
                          <>
                          <Chip onClick={()=>scoreExpand(iSc)}
                            icon={sc.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={sc.eg.length} 
                            className='app-bg-g'
                            sx={{
                              //backgroundColor:'rgb(99, 136, 137)',
                              color: '#fff',
                              fontWeight: 'bold',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              },
                              width:'65px'
                            }} 
                          />
                          </>
                        )}
                        { iamTEA && !isSample && !isShared && isMyEssay &&
                          (sc.c!='GM' || isOK_GM) && (sc.c!='SP' || isOK_SP) && 
                          (sc.c!='VC' || isOK_VC) && (sc.c!='ST' || isOK_ST) && 
                          <Chip onClick={()=>popCmtView(iSc, sc)}
                            icon={<img src='../../img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                            label={'Comment'}
                            className='app-bg-g'
                            sx={{
                              //backgroundColor:'rgb(99, 136, 137)',
                              color: '#fff',
                              fontWeight: 'bold', marginLeft:'2px',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }} 
                          />
                        }
                      </Grid>
                      </>
                      }
                      <Grid item xs={12}>
                        <Box display='flex' width='100%'>
                          <Box flexGrow={1} paddingRight={'.2rem'}>
                            <Box flexGrow={1} 
                              sx={{
                                border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', display:'flex',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}>
                              <Box className='app-bg-g' sx={{ borderRadius:'50px', 
                                //backgroundColor:'rgb(99, 136, 137)', 
                                width:`${sc.p}%`, height:'100%', padding:'3px 0 3px 15px', alignItems:'center', display:'flex', fontWeight:'bold' 
                              }}>
                                {sc.v}/{recEssay.version>=3?'10':'4'} {sc.n}
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            { isResubmitting && 
                              <span>Resubmitting..</span>
                            }
                            
                            {/* { sc.eg &&
                              <div>{sc.eg.length}</div>
                            } */}
                            { sc.eg && sc.eg.length > 0 && (
                              <>
                              <Chip onClick={()=>scoreExpand(iSc)}
                                icon={sc.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={sc.eg.length} 
                                className='app-bg-g'
                                sx={{
                                  //backgroundColor:'rgb(99, 136, 137)',
                                  color: '#fff',
                                  fontWeight: 'bold',
                                  [`& .${chipClasses.icon}`]: {
                                    color: '#fff'
                                  },
                                  width:'65px'
                                }} 
                              />
                              </>
                            )}
                            { iamTEA && !isSample && !isShared && isMyEssay &&
                              (sc.c!='GM' || isOK_GM) && (sc.c!='SP' || isOK_SP) && 
                              (sc.c!='VC' || isOK_VC) && (sc.c!='ST' || isOK_ST) && 
                              <Chip onClick={()=>popCmtView(iSc, sc)}
                                icon={<img src='../../img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                                label={'Comment'}
                                className='app-bg-g'
                                sx={{
                                  //backgroundColor:'rgb(99, 136, 137)',
                                  color: '#fff',
                                  fontWeight: 'bold', marginLeft:'2px',
                                  [`& .${chipClasses.icon}`]: {
                                    color: '#fff'
                                  }
                                }} 
                              />
                            }
                          </Box>
                        </Box>
                      </Grid>

                      { sc.expand && sc.sub && 
                        <ScoreSubExpand records={sc.sub} essay={recEssay} allowMark={iamTEA && !isShared && isMyEssay} />
                      }
                      { sc.expand && sc.scoreInfo &&
                      <Grid container>
                        <Grid item xs={12}>
                          <Box>
                            { sc.scoreInfo.blocks && sc.scoreInfo.blocks.length>0 &&(
                            <>
                              <Box display={'flex'} alignItems={'center'}>
                                <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {sc.scoreInfo.blocks[0].title}
                                </Typography>
                                { !sc.scoreInfo.blocks[0].hide && (
                                  <Fab onClick={()=> spellingErrorsView(sc)} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                    <OpenInNewIcon />
                                  </Fab>
                                )}
                              </Box>
                              { sc.scoreInfo.blocks[0].subTitles && sc.scoreInfo.blocks[0].subTitles.map((rSubT, iSubT)=>(
                                <Box>
                                  <Grid item xs={12} mt={0}>
                                    <Box display={'flex'} alignItems={'center'}>
                                      <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                        {rSubT}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Box>
                              ))}
                            </>  
                            )}
                            { (!sc.scoreInfo.blocks || sc.scoreInfo.blocks.length<1) &&(
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {sc.scoreInfo.title}
                              </Typography>
                              <Fab onClick={()=> spellingErrorsView(sc)} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)', minWidth:'40px'}}>
                                <OpenInNewIcon />
                              </Fab>
                            </Box>
                            )}
                          {/* <Chip onClick={()=> spellingErrorsView(sc)}
                            icon={<OpenInNewIcon />}
                            sx={{
                              backgroundColor:'rgb(99, 136, 137)',
                              color: '#F9EFDB',
                              fontWeight: 'bold', marginLeft: '5px',
                              [`& .${chipClasses.icon}`]: {
                                color: '#fff'
                              }
                            }} 
                            /> */}
                          </Box>
                        </Grid>
                        { sc.scoreInfo.blocks && sc.scoreInfo.blocks.length>1 &&(
                        <Grid item xs={12} mt={0.5}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                              {sc.scoreInfo.blocks[1].title}
                            </Typography>
                            <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                              <OpenInNewIcon />
                            </Fab>
                          </Box>
                          { sc.scoreInfo.blocks[1].subTitles && sc.scoreInfo.blocks[1].subTitles.map((rSubT, iSubT)=>(
                            <Grid item xs={12} mt={0}>
                              <Box display={'flex'} alignItems={'center'}>
                                <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                  {rSubT}
                                </Typography>
                                {/* { iSubT==0 && sc.scoreInfo.rareWords && sc.scoreInfo.rareWords.length>0 && (
                                  <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                    <OpenInNewIcon />
                                  </Fab>
                                )} */}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        )}
                        { sc.scoreInfo.subTitles && sc.scoreInfo.subTitles.map((rSubT, iSubT)=>(
                          <Grid item xs={12} mt={0}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                {rSubT}
                              </Typography>
                              {/* { iSubT==0 && sc.scoreInfo.rareWords && sc.scoreInfo.rareWords.length>0 && (
                                <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                                  <OpenInNewIcon />
                                </Fab>
                              )} */}
                            </Box>
                          </Grid>
                        ))}
                        {/* <Box sx={{maxWidth:'450px', width:'100%', paddingTop:'0.5rem'}}>
                          <Grid item xs={12} mt={0.3} sx={{border:'1px solid #333'}}>
                            <Grid container>
                              <Grid item xs={6} sx={{borderRight:'1px solid #333', textAlign:'center'}}>
                                <Typography sx={{marginLeft:'1rem'}}>WORD</Typography>
                              </Grid>
                              <Grid item xs={6} sx={{textAlign:'center'}}>
                                <Typography sx={{marginLeft:'1rem'}}>LEVEL</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          { sc.scoreInfo.levelItems && sc.scoreInfo.levelItems.map((lt, iLt)=>(
                            <Grid item xs={12} mt={0.3} sx={{borderBottom:'1px solid #333', borderLeft:'1px solid #333', borderRight:'1px solid #333'}}>
                              <Grid container>
                                <Grid item xs={6} sx={{borderRight:'1px solid #333', textAlign:'center'}}>
                                  <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                    {lt.w}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:'center'}}>
                                  <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                                    {lt.l}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Box> */}
                      </Grid>
                      }
                      <Grid item xs={10}>
                        { comments[sc.c] && comments[sc.c].list.length>0 && 
                          comments[sc.c].list.map(cmm=>(
                            <>
                            <div style={{fontSize:'0.8rem', display:'flex',}}>
                              <div>
                                <img src='/img/teacher-dark.png' style={{width:'25px', marginRight:'3px', marginBottom:'0'}} />
                              </div>
                              <div className='line-cmt' style={{marginTop:'3px'}}>
                                <span style={{color:'rgba(0,0,0,0.8)', letterSpacing:1}}>Tutor's comment:</span>{' ' + cmm.comment}
                              </div>
                            </div>
                            </>
                          ))
                        }
                      </Grid>
                    </Grid>
                  ))}
                  </Box>
                </Box>
                <Box>
                {/* { !printMode &&
                  <Box display={'flex'} justifyContent={'right'}>
                  <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0.5rem'}}>
                    v{recVersion == 3.1 ? '3.1' :recVersion == 3 ? '3.0' : recVersion == 2.2 ? '2.2' : recVersion == 2.1 ? '2.1' : recVersion == 2 ? '2.0' : '1.0'}
                  </Typography>
                  </Box>
                } */}
                { printMode && qrValue &&
                  <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', paddingLeft:'1rem'}}>
                    <div style={{textAlign:'center', marginTop:0, borderRadius:'0'}}>
                      <div style={{color:"rgba(19,130,117,1)", fontWeight:'900', textAlign:'center', marginBottom:'.5rem', lineHeight:1.1}}>Scan the QR Code <br/>for detailed feedback!</div>
                      <QRCodeSVG 
                      value={qrValue} 
                      //value={'ABCD'}
                      size={150} 
                      level={"Q"} includeMargin={false}
                      fgColor={"rgba(19,130,117,1)"}
                      style={{borderRadius:'0'}}
                      />
                    </div>
                  </div>
                }
                </Box>
              </Box>
              <Grid item xs={12}>
                <Box display='flex' width='100%'>
                  <Box flexGrow={1} paddingRight={'.2rem'}>
                    <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', marginBottom:'4px'}} flexGrow={1}>
                      <Box ref={boxOverall} id="boxOverall" sx={{ borderRadius:'50px', backgroundColor:'#1976d2', padding:'3px 0 3px 15px', width:`${recEssay.avgScoreP}%`, height:'100%', alignItems:'center', display:'flex', fontWeight:'bold'  }}>
                        {/* Overall {Math.floor(recEssay.avgScoreP)}% */}
                        Overall {Math.round(recEssay.avgScoreP)}%
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    { !printMode &&
                    <>
                    <div style={{width:'65px'}}></div>
                    { iamTEA && !isSample && !isShared && isMyEssay &&
                      <Chip onClick={()=>popCmtView(-1, {c:'OA'})}
                        icon={<img src='/img/teacher.png' style={{width:'25px', marginTop:'0', marginBottom:'0'}} />} 
                        label={'Comment'}
                        className='app-bg-g'
                        sx={{
                          //backgroundColor:'rgb(99, 136, 137)',
                          color: '#fff',
                          fontWeight: 'bold', marginLeft:'2px',
                          [`& .${chipClasses.icon}`]: {
                            color: '#fff'
                          }
                        }}
                      />
                    }
                    </>
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={10}>
                { comments.OA && comments.OA.list.length>0 && 
                  comments.OA.list.map(cmm=>(
                    <>
                    <div style={{fontSize:'0.8rem', display:'flex',}}>
                      <div>
                        <img src='/img/teacher-dark.png' style={{width:'25px', marginRight:'3px', marginBottom:'0'}} />
                      </div>
                      <div className='line-cmt' style={{marginTop:'3px'}}>
                        <span style={{color:'rgba(0,0,0,0.8)', letterSpacing:1}}>Tutor's comment:</span>{' ' + cmm.comment}
                      </div>
                    </div>
                    </>
                  ))
                }
              </Grid>
            </>
            }
          </Card>
        </Grid>
        <Grid item xs={12} md={6} pl={0}>
          { recEssay && recEssay.suggestions && recEssay.suggestions.length>0 &&
            <Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px'}}>
              <Grid item xs={12}>
                <Box sx={{fontWeight:'bold', fontSize:'2rem'}}>SUGGESTIONS</Box>
              </Grid>
              <Grid item xs={12}>
                { recEssay && recEssay.suggestions && recEssay.suggestions.map((sg,iSg)=>(
                  // <Box key={'sug_' + iSg}>{sg}</Box>
                  <ListItem key={'sug_' + iSg} sx={{alignItems:'flex-start'}}>
                    <ListItemIcon sx={{minWidth:'40px'}}>
                      <AssignmentIcon sx={{color:'rgb(157, 188, 152)', marginTop:'8px'}} />
                    </ListItemIcon>
                    <ListItemText
                      primary={sg}
                      // secondary={secondary ? 'Secondary text' : null}
                    />
                  </ListItem>
                ))}
              </Grid>
            </Card>
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} mt={1}>
              { !isSample && !isShared && (oUser && oUser.token != 'NA') && (
              <>
                { isMyEssay &&
                <>
                <Button onClick={onDelete} color="error" variant="outlined" style={{marginRight:'2px'}}>DELETE</Button>
                <Button onClick={onShare} color="success" variant="outlined" style={{marginRight:'2px'}}>SHARE</Button>
                <Button onClick={onScoreView} color="success" variant="outlined" style={{marginRight:'2px'}}>SCORE SIMULATION</Button>
                </>
                }
                {isSa && (
                  <>  
                  <Button onClick={onShowLog} color="success" variant="outlined" style={{marginRight:'2px'}}>VIEW LOG</Button>
                  <Button onClick={onReportDownload} color="success" variant="outlined" >
                    REPORT
                  </Button>
                  </>
                )}
              </>
              )}
            </Grid>
            <Grid item xs={6} mt={1} style={{textAlign:'right'}}>
              { isSa && 
              <Chip label='EXERCISE'
                onClick={()=> browseExercise()}
                icon={<OpenInNewIcon />}
                className='app-bg-g'
                sx={{
                  //backgroundColor:'rgb(99, 136, 137)',
                  color: '#fff',
                  fontWeight: 'bold', marginLeft: '5px', marginRight:'5px',
                  [`& .${chipClasses.icon}`]: {
                    color: '#fff'
                  }
                }}
              />
              }
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Fab color="primary" aria-label="add" onClick={onShowFeedback} sx={{ marginLeft: '8px' }}>
              <AddIcon />
            </Fab>
          </Box>
        </Grid> */}
      </Grid>
      
      <SuggestionPopup
        open={suggestionPopOpen} onClose={suggestionPopClose}
        suggestPopItems={suggestPopItems}
      />
      <PopErrorAdd 
        // title={popAddTitle}
        open={popErrorAddOpen && !isShared && isMyEssay} onClose={popErrorAddClose}
        essay={recEssay}
        popErrorAddLine={popErrorAddLine}
        popErrorAddStartIndex={popErrorAddStartIndex}
        words={popErrorAddWords}
        onAddDone={onErrorAddDone}
      />
      <PopGMErrors
        open={popGMErrorsOpen} onClose={popGMErrorsClose}
        scoreInfo={scoreInfoGM}
		    essay={recEssay}
        allowMark={iamTEA && !isShared && isMyEssay}
        onMarkDone={onMarkDoneGM}
      />
      <PopSPErrors
        open={popSpellingErrorsOpen} onClose={popSpellingErrorsClose}
        scoreInfo={scoreInfoSP}
        essay={recEssay}
        allowMark={iamTEA && !isShared && isMyEssay}
        onMarkDone={onMarkDoneSP}
      />
      <PopVCAErrors
        open={popVCErrorsOpen} onClose={popVCErrorsClose}
        scoreInfo={scoreInfoVC}
		    essay={recEssay}
        allowMark={iamTEA && !isShared && isMyEssay}
        onMarkDone={onMarkDoneVCA}
      />
      <PopVCXErrors
        open={popVCXOpen} onClose={popVCXClose}
        scoreInfo={scoreInfoVCX}
		    essay={recEssay}
        allowMark={iamTEA && !isShared && isMyEssay}
        onMarkDone={onMarkDoneVCX}
      />
      <PopShare
        open={popShareOpen} onClose={popShareClose}
        scoreInfo={scoreInfoVCX}
		    essay={recEssay}
        allowMark={iamTEA}
        onMarkDone={onMarkDoneVCX}
      />
      <PopScore
        open={popScoreOpen} onClose={popScoreClose}
        scoreInfo={scoreInfoVCX}
		    essay={recEssay}
      />
	    <PopShowLog
        open={popShowLogOpen} onClose={popShowLogClose}
        log={essayLog}
      />
	    <PopShowFeedback
        open={popShowFeedbackOpen} onClose={popShowFeedbackClose}
		    onSubmit={handleFeedbackSubmit}
        feedbackData={feedbackData}
		    setFeedbackData={setFeedbackData}
      />
      <PopComment
        essayId={recEssay.essayId}
        scoreType={popCmtType}
        commentText={popCmtText}
        open={popCmtOpen} onClose={popCmtClose}
        onSave={popCmtSave}
      />
      <PopInProgress
        essayId={recEssay}
        open={popInprogressOpen} onClose={popInprogressClose}
      />
      <PopResubmit
        recEssay={recEssay}
        open={popResubmitOpen} onClose={popResubmitClose}
        onResubmitPosted={onResubmitPosted}
      />
    </Box>
    }
    { startCheckStatus &&
      <EssayStatus
        essayId={recEssay.essayId}
        essayFormDone={essayStatusCheckDone}
        startCheck={startCheckStatus}
      />
    }
    { isLoading &&
      <CircularProgress />
    }
    </>
  );
}