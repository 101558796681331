import { AppHelper } from '../AppHelper';

const scoreCodesV2_helper = [
	{ c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
	{ c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
	{ c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
	{ c:'ST', c2: 'structure_and_flow', n:'Structure and Flow', v:0, p:0, eg: [], sub: [], expand: false }
];
const scoreCodesV4_helper = [
	{ c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
	{ c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
	{ c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
	{ c:'ST', c2: 'structure_and_flow', n:'Structure and Flow', v:0, p:0, eg: [], sub: [], expand: false },
	{ c:'CT', c2: 'content', n:'Content', v:0, p:0, eg: [], sub: [], expand: false },
	{ c:'CR', c2: 'conent_relevance', n:'Content Relevance', v:0, p:0, eg: [], sub: [], expand: false },
];

export const EssayHelper = {
	async essayGradeGet(token){
		const grade = [];
		try {
		  const response = await AppHelper.apiPost('api/grade/list', {token: token});
		  if (response && response.status && response.data) {
				response.data.forEach((x)=>
				{
					grade.push({
						id:x.gradeId,
						v:x.code,
						n:x.name,
						minW: x.maxWord ? x.maxWord : 100
					});
				});
		  } else {
				console.error('Failed to fetch grades:', response);
		  }
		} catch (error) {
		  	console.error('Error fetching grades:', error);
		}
		return grade;
	},
	essayInfoGetForPopScore(_res, _rulesJson){
		var _rec = {
      essayId: _res.data.essayId, profileId: _res.data.profileId, topic:_res.data.topic, grade:_res.data.grade, 
      text: _res.text, //_res.correctionResult ? _res.correctionResult : 'NA',
      scores: [...scoreCodesV2_helper],
      email:_res.email,
	    student:_res.student,
      suggestions: [],//[...scoreResult.suggestions],
      avgScoreP: 0,
      imgs:[],
			score:_res.score
    };
		//console.log('essayInfoGet', _res, _rulesJson, _rec);
		var _scTotal = 0;
		_rec.scores.forEach(_sc=>{
			//_sc.eg = [];
			var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == _sc.c);
			const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
			_sc.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
			_sc.v = parseFloat(_sc.v.toFixed(2));
			
			var _rule = _rulesJson.find(r=>r.code == _sc.c);
			
			if(_sc.c == 'GM'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'SP'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'VC'){
				var _vVCA = 5;
				var _vVCX = 5;
				if(_rule && _rule.data){
					_vVCA = getScoreL2('VCA', _arrScRec, _rule, 50, 5);
					_vVCX = getScoreL2('VCX', _arrScRec, _rule, 50, 5);
				}
				_sc.v = getFloat((_vVCA+_vVCX)/(1*2));
				//console.log('rule-VCA', _vVCA, 'rule-VCX', _vVCX, 'v', _sc.v);
			}
			// if(_sc.c == 'VC' && _arrScRec.length==1){
			// 	_sc.v = getFloat(_sc.v/2);
			// }
			else if(_sc.c == 'ST'){
				//_sc.v = getFloat(_sc.v * 2.5);
				var _stORG = 2.5, _stTRA = 2.5, _stPAR = 2.5, _stTIT = 2.5;
				if(_rule && _rule.data){
					_stORG = getScoreL2('O', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTRA = getScoreL2('T', _arrScRec, _rule, 25, 2.5, 2.5);
					_stPAR = getScoreL2('P', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTIT = getScoreL2('TR', _arrScRec, _rule, 25, 2.5, 2.5);
				}
				_sc.v = getFloat((_stORG + _stTRA + _stPAR + _stTIT)/(1*4));
				//console.log('_ST', _sc.c2, _stORG, _stTRA, _stPAR, _stTIT, _sc.v, _arrScRec);
			}

			_sc.v_r = _sc.v;
			if(_rule){
				_sc.v_r = getFloat(_sc.v * (_rule.v/25));
			}
			_scTotal += _sc.v_r;
			//console.log(_sc.c, _sc.v, _sc.v_r, _rule.v);
		});
		_rec.avgScoreP_r = (_scTotal / (10*4))*100;
		_rec.avgScoreP_r = Math.round(_rec.avgScoreP_r);
		//console.log('essayInfoGet', _rec, _scTotal);
		//console.log('essayInfoGet', _rec.scores, _scTotal);
		return _rec;
	},
	essayInfoGet(_res, _rulesJson){
		var _rec = {
      essayId: _res.data.essayId, profileId: _res.data.profileId, topic:_res.data.topic, grade:_res.data.grade, 
      text: _res.text, //_res.correctionResult ? _res.correctionResult : 'NA',
      scores: _res.score[0].version=='4.00' ? [...scoreCodesV4_helper]: [...scoreCodesV2_helper],
      email:_res.email,
	    student:_res.student,
      suggestions: [],//[...scoreResult.suggestions],
      avgScoreP: 0,
      imgs:[],
			score:_res.score
    };
		//console.log('essayInfoGet', _res, _rulesJson, _rec);
		var _scTotal = 0;
		_rec.scores.forEach(_sc=>{
			if(!_sc.eg)_sc.eg = [];
			var _arrScRec = _res.score.filter(_rs=>_rs.scoreCode == _sc.c);
			const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
			_sc.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
			_sc.v = parseFloat(_sc.v.toFixed(2));
			
			var _rule = _rulesJson.find(r=>r.code == _sc.c);
			
			if(_sc.c == 'GM'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					//console.log('GM-_errPercent', _errPercent);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<=x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'SP'){
				if(_rule && _rule.data && _arrScRec && _arrScRec.length>0){
					var _errPercent = getErrorPercentByScore(_arrScRec[0].score);
					var _tmpScoreSP = 1;
					var _isScoreSPSet = false;
					_rule.data.forEach((x, iX)=>{
						if(!_isScoreSPSet && _errPercent<=x.v){
							_tmpScoreSP = 10-iX;
							_isScoreSPSet = true;
						}
					});
					_sc.v = _tmpScoreSP;
					//console.log('SP', _tmpScoreSP, _errPercent, _arrScRec, _rule.data);
				}
			}
			else if(_sc.c == 'VC'){
				var _vVCA = 5;
				var _vVCX = 5;
				if(_rule && _rule.data){
					//_vVCA = getScoreL2('VCA', _arrScRec, _rule, 50, 5);
					_vVCA = getScore_byRulePercent('VCA', _arrScRec, _rule, 10, 5);
					//console.log('VCA', _vVCA, _arrScRec, _rule);
					//_vVCX = getScoreL2('VCX', _arrScRec, _rule, 50, 5);
					_vVCX = getScore_byRulePercent('VCX', _arrScRec, _rule, 10, 5);
					//console.log('VCX', _vVCX);
				}
				//console.log('VC', getFloat(_vVCA+_vVCX));
				_sc.v = getFloat(_vVCA+_vVCX);
				//_sc.v = getFloat((_vVCA+_vVCX)/(1*2));
				//console.log('rule-VCA', _vVCA, 'rule-VCX', _vVCX, 'v', _sc.v);
			}
			// if(_sc.c == 'VC' && _arrScRec.length==1){
			// 	_sc.v = getFloat(_sc.v/2);
			// }
			else if(_sc.c == 'ST'){
				//_sc.v = getFloat(_sc.v * 2.5);
				var _stORG = 2.5, _stTRA = 2.5, _stPAR = 2.5, _stTIT = 2.5;
				if(_rule && _rule.data){
					_stORG = getScoreL2('O', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTRA = getScoreL2('T', _arrScRec, _rule, 25, 2.5, 2.5);
					_stPAR = getScoreL2('P', _arrScRec, _rule, 25, 2.5, 2.5);
					_stTIT = getScoreL2('TR', _arrScRec, _rule, 25, 2.5, 2.5);
				}
				_sc.v = getFloat((_stORG + _stTRA + _stPAR + _stTIT)/(1*4));
				//console.log('_ST', _sc.c2, _stORG, _stTRA, _stPAR, _stTIT, _sc.v, _arrScRec);
			}

			_sc.v_r = _sc.v;
			if(_rule){
				_sc.v_r = getFloat(_sc.v * (_rule.v/25));
			}
			_scTotal += _sc.v_r;
			//console.log(_sc.c, _sc.v, _sc.v_r, _rule.v);
		});
		_rec.avgScoreP_r = (_scTotal / (10*4))*100;
		_rec.avgScoreP_r = Math.round(_rec.avgScoreP_r);
		//console.log('essayInfoGet', _rec, _scTotal);
		//console.log('essayInfoGet', _rec.scores, _scTotal);
		return _rec;
	},
	async rulesJsonByProfile(token, grade, saMode=false){
		const _res = await AppHelper.apiPost('api/essay/score/rule/get', {token: token, grade:grade, isSA:saMode?1:0});
		if (_res && _res.status && _res.data)
			return EssayHelper.rulesJson(_res.data);
		else return [];
	},
	async rulesJsonByProfileId(token, profileId, grade, saMode=false){
		const _res = await AppHelper.apiPost('api/essay/score/rule/getByProfileId', {token:token, profileId:profileId, grade:grade, isSA:saMode?1:0});
		if (_res && _res.status && _res.data)
			return EssayHelper.rulesJson(_res.data);
		else return [];
	},
	rulesJson(recordsFromApi){
		var _records = [];
		recordsFromApi.forEach(r => {
			var _rec = {code:r.code, expand:false, data:[]};
			if(r.data && r.data.length>0){
				var _recOA = r.data.find(x=>x.title == 'overall');
				if(_recOA){
					_rec.v = _recOA.value;
					//console.log(_rec.code, _rec.v);
					//_rec.v0 = _rec.v;// * 4;
					_rec.title = r.code == 'GM' ? 'Grammar' : r.code == 'SP' ? 'Spelling' : r.code == 'VC' ? 'Vocabulary' : r.code == 'ST' ? 'Structure & Flow' : '-';
					_records.push(_rec);

					const _textErrorPercent = '/10';//'/10 : Error % (Error Count / Total Word Count) must be below';
					if(r.code=='VC'){
						var _recVCA = r.data.find(x=>x.title == 'VCA');
						_rec.data.push({title:'Vocabulary Appropriateness', v:_recVCA? _recVCA.value:50, code:'VCA'});
						_rec.data[0].data = [];
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-10', 0, 'Score 10' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-9', 1, 'Score 9' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-8', 2, 'Score 8' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-7', 3, 'Score 7' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-6', 4, 'Score 6' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-5', 5, 'Score 5' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-4', 6, 'Score 4' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-3', 7, 'Score 3' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-2', 8, 'Score 2' + _textErrorPercent, true) );
						_rec.data[0].data.push( getRuleL2ByCode_1_10(r.data, 'VCA-ERR-%-1', 9, 'Score 1' + _textErrorPercent, true) );
						
						var _recVCX = r.data.find(x=>x.title == 'VCX');
						_rec.data.push({title:'Vocabulary Complexity', v:_recVCX? _recVCX.value:50, code:'VCX'});
						_rec.data[1].data = [];
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-10', 10, 'Score 10' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-9', 9, 'Score 9' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-8', 8, 'Score 8' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-7', 7, 'Score 7' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-6', 6, 'Score 6' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-5', 5, 'Score 5' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-4', 4, 'Score 4' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-3', 3, 'Score 3' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-2', 2, 'Score 2' + _textErrorPercent, true) );
						_rec.data[1].data.push( getRuleL2ByCode_1_10(r.data, 'VCX-ERR-%-1', 1, 'Score 1' + _textErrorPercent, true) );
					}
					else if(r.code=='ST'){
						_rec.data.push( getRuleL2ByCode(r.data, 'O', 25, 'Organisation') );
						addST_L3(_rec.data[0], 'O', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'T', 25, 'Transitions') );
						addST_L3(_rec.data[1], 'T', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'P', 25, 'Paragraphing') );
						addST_L3(_rec.data[2], 'P', r.data);
						_rec.data.push( getRuleL2ByCode(r.data, 'TR', 25, 'Title Relevance') );
						addST_L3(_rec.data[3], 'TR', r.data);
					}
					else if(r.code=='SP'){
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-10', 0, 'Score 10' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-9', 1, 'Score 9' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-8', 2, 'Score 8' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-7', 3, 'Score 7' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-6', 4, 'Score 6' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-5', 5, 'Score 5' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-4', 6, 'Score 4' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-3', 7, 'Score 3' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-2', 8, 'Score 2' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode_1_10(r.data, 'SP-ERR-%-1', 9, 'Score 1' + _textErrorPercent, true) );
					}
					else if(r.code=='GM'){
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-10', 0, 'Score 10' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-9', 1, 'Score 9' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-8', 2, 'Score 8' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-7', 3, 'Score 7' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-6', 4, 'Score 6' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-5', 5, 'Score 5' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-4', 6, 'Score 4' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-3', 8, 'Score 3' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-2', 8, 'Score 2' + _textErrorPercent, true) );
						_rec.data.push( getRuleL2ByCode(r.data, 'GM-ERR-%-1', 9, 'Score 1' + _textErrorPercent, true) );
					}
				}
			}
		});
		return _records;
	},
	scoreVersionNumFromScore(essayData){
		if(!essayData || !essayData.scores) return 1;
		const argScoreList = essayData.scores;
		if(!argScoreList || argScoreList.length<1) return 1;
		var _version = parseFloat(argScoreList[0].version);
		if(!_version == NaN) _version = 1;
		return _version;
	}
}

const getErrorPercentByScore = (argScore)=>{
	if(argScore == 10) return 0;
	if(argScore == 9) return 1;
	if(argScore == 8) return 2;
	if(argScore == 7) return 3;
	if(argScore == 6) return 4;
	if(argScore == 5) return 5;
	if(argScore == 4) return 6;
	if(argScore == 3) return 7;
	if(argScore == 2) return 8;
	if(argScore == 1) return 9;
}
const getScoreL2 = (argSubCode, _arrScRec, _rule, subTotal, defaultValue, baseV = 1)=>{
	var _scRec = _arrScRec.find(_rs=>_rs.subScoreCode == argSubCode);
	var _ruleRec = _rule.data.find(x=>x.code == argSubCode);
	if(argSubCode=='VCA'){
		//console.log('VCA', _scRec, _ruleRec);
	}
	if(_scRec && _ruleRec){
		return getFloat((_scRec.score*baseV) * (_ruleRec.v/subTotal));
	}
	if(_scRec){
		return getFloat(_scRec.score);
	}
	return defaultValue;
}
const getScore_byRulePercent = (argSubCode, _arrScRec, _rule, _scoreTotal, defaultValue)=>{
	var _scRec = _arrScRec.find(_rs=>_rs.subScoreCode == argSubCode);
	var _ruleRec = _rule.data.find(x=>x.code == argSubCode);
	if(_scRec && _ruleRec){
		//console.log(_scRec.score, _ruleRec.v, _scoreTotal, _ruleRec);
		return getFloat(((_scRec.score/_scoreTotal)*_ruleRec.v)/_scoreTotal);
		//return getFloat((_scRec.score*baseV) * (_ruleRec.v/subTotal));
	}
	if(_scRec){
		return getFloat(_scRec.score);
	}
	return defaultValue;
}
const getFloat = (arg)=>{
	return parseFloat(arg.toFixed(2));
}
const getRuleL2ByCode = (argData, argCode, defaultValue, argTitle, pop=false)=>{
	var _rec = argData.find(x=>x.title == argCode);
	return {title:argTitle, v:_rec?_rec.value:defaultValue, code:argCode, pop:pop};
}
const getRuleL2ByCode_1_10 = (argData, argCode, defaultValue, argTitle, pop=false)=>{
	var _rec = argData.find(x=>x.title == argCode);
	var _v = (_rec?_rec.value:defaultValue);
	return {title:argTitle, v:_v, code:argCode, pop:pop};
}
const addST_L3 = (arg, argCode, argData)=>{
	arg.data = [];
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-1`, 25, '1/4 (Needs Improvement)'));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-2`, 50, '2/4 (Developing) '));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-3`, 75, '3/4 (Competent)'));
	arg.data.push( getRuleL2ByCode(argData, `${argCode}-4`, 100, '4/4 (Proficient)'));
};